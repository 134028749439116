import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import DetailPageHeader from "./detail-page-header";
import DetailPageBody from "./detail-page-body";
import UserContext from '../../../../user/user-context';

import NotAvailableComponent from "../../statuses/not-available-component";
import LoadingComponent from "../../statuses/loading-component";
import PrinterContainerModal from "../../modals/printer-container-modal";
import SetBalanceContainerModal from "../../../modals/set-balance-container-modal";
import QuickLinksSection from "../../quick-actions/quick-links-section/quick-links-section";
import DetailPageRelatedList from './detail-page-related-list'

import * as tc from '../../../configurations/text-constants'
 
const DetailPage = ({service, children, configs}) => {
  
    const { t } = useTranslation();
    const { id } = useParams()
 
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)

    const [relatedList, setRelatedList] = useState()
 
    const [showPrinterModal, setShowPrinterModal] = useState(false)
    
 
    document.title = `${item? item.name: t(configs.object.label)}`
    
    const user = useContext(UserContext)
  
    //  general actions for all elements
    configs.actions.remove.handler = async (id) => {
        const response = await service.remove({payload: {id}, user: user }, dispatch)
        if(response.success)
            navigate(`/${configs.object.basePath}`)
    }

    useEffect(() => {
        service.get({payload: {id}, user }, dispatch)
 
        const relatedList = configs.relatedlist
        // set related list
        setRelatedList(relatedList)
           
        // Find and set handlers for print and set balance actions
        if(configs.c_actions) {
            const printAction = configs.c_actions.find(a => a.label == tc.BUTTON_PRINT_INFO_LABEL)
            if(printAction) {
                printAction.handler = () => setShowPrinterModal(true)
            }
        }
    }, [])
  
    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />
 
    return (
        <div className="mb-3">
            <DetailPageHeader user={user} configs={configs} element={item}/>
            {
                configs.layout.enableQuickActions &&
                <QuickLinksSection configs={configs} />
            }
            <DetailPageBody user={user} element={item} configs={configs} />

            {children}

            {
                item && relatedList && 
                <DetailPageRelatedList element={item} list={relatedList} user={user} />
            }

            {
                showPrinterModal &&
                <PrinterContainerModal close={()=>setShowPrinterModal(false)} configs={configs} element={item}/>
            }
        </div>
    )
}

export default DetailPage