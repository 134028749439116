import React, { useState, useLayoutEffect, useRef, useEffect, Fragment, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import _ from "lodash" 
import { useTranslation } from 'react-i18next';

import Search from "../../search/search";
import BasicTreeItem from './basic-tree-item'
import BasicTreeHeader from "./basic-tree-header";
import UserContext from '../../../user/user-context';
import { getFormattedLabel } from "../../layouts/fields/utility-field";
import './basic-tree.css'

import * as tc from '../../configurations/text-constants'
import AutoHeightContainer from "../../auto-height-container/auto-height-container";

// Función para obtener la fecha en el formato yyyymm
const formatDate = (year, month) => {
    return `${year}${String(month + 1).padStart(2, '0')}`; // Sumar 1 porque los meses empiezan en 0
};

// Función para obtener el mes actual
const getCurrentMonth = () => {
    const currentDate = new Date();
    return formatDate(currentDate.getFullYear(), currentDate.getMonth());
};

// Función para obtener el nombre del mes en español
const getMonthName = (month, t) => {
    return t(`MONTH_NAME_${month + 1}`);
};

// Obtener los últimos 3 meses
const getLastThreeMonths = (t) => {
    const currentDate = new Date();
    let months = [];

    for (let i = 0; i < 3; i++) {
        let month = currentDate.getMonth() - i;
        let year = currentDate.getFullYear();

        // Si el mes es negativo, ajustar el año
        if (month < 0) {
            month += 12;
            year -= 1;
        }

        months.push({
            label: getMonthName(month, t),
            value: formatDate(year, month),
        });

    }

    return months;
};
  
const BasicTree = ({service, configs}) => {
    
    const user = useContext(UserContext)

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
  
    const months = getLastThreeMonths(t);

    const [text, setText] = useState('')
    const [internalRoot, setInternalRoot] = useState()
    const [loading, setLoading] = useState(false); // Estado de carga
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
      
    document.title = `${t(configs.object.pluralLabel)}`
        
    const handleChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const moveHandler = async (item, newparentid) => {
        const response =  await service.update({payload: {...item, parent_id: newparentid}, user: user}, dispatch)

        if(response.success)
            navigate(0)
    }

    configs.actions.move.handler = moveHandler

    const setDefaultProperties = (node) => {

        node.closed = false; 

        if(!node.children)
            return

        // opened
        // searched
        for (const key in node.children) {
            setDefaultProperties(node.children[key])
        }

        return node
    }

    const searchHandler = (text) => { 
        search(internalRoot, text) 
        setInternalRoot(internalRoot)
        setText(text) 
    }
 
    const search = (node, text) => { 
        setDefaultProperties(node)
        searchTextRecursive(node, text) 
    }
  
    const searchTextRecursive = (node, text) => {
        // matched -- empty text
        if(!text) {
            return true
        }

        // its children matched
        let oneChildMatched = false
        if(node.children) {  
            
            let child
            
            for(let index in node.children) {
 
                child = node.children[index]
                oneChildMatched |= searchTextRecursive(child, text)
            }
        }

        // open current node if one of its children is visible
        if(!oneChildMatched) {
            node.closed = true 
        }

        // matched .. contains the text
        if(node.name.toLowerCase().indexOf(text.toLowerCase()) !== -1)
            return true
          
        return oneChildMatched
    }
 
    const getTreeHeaders = (configs) => {
 
        return configs.treelist.items.map((c, i) => { 
            return (
                <th key={i} className={`tree-list-column ${c.isCompactVisible? '': 'd-none d-md-table-cell'}`}> 
                    {getFormattedLabel(c, t, {user})}
                </th>
            )
        })
    }
 
    
 
    useEffect(()=> {
        const load = async () => {

            setLoading(true); // Activar el estado de carga

            const response = await service.getTree({period: selectedMonth, user: user}, dispatch)
           
            if(response.success)
                setInternalRoot( _.cloneDeep(response.data))

            setLoading(false); // Desactivar el estado de carga
        }
 
        load()
        
    }, [selectedMonth])
  
    return (
         
        <Fragment>

            <BasicTreeHeader configs={configs} />
             
            <div className="custom-tree-index"> 
                <div className="card"> 
                    <div className="card-body">
                        <div className="vstack gap-2">
                            <div className="row">
                                <div className="col-12 col-md-6 mb-2">
                                    <select className="form-select" value={selectedMonth} onChange={handleChange}>
                                        {months.map((month) => (
                                            <option key={month.value} value={month.value}>
                                            {month.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Search handler={searchHandler} placeholder={t(tc.INPUT_PLACEHOLDER_SEARCH)}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="container-fluid">
                                    <table className="table table-bordered table-hover table-tree-header">
                                        <thead>
                                        <tr className="text-center table-secondary text-uppercase ">
                                            <th>{t(tc.OBJECT_FIELD_GENERIC_NAME)}</th>
                                            { getTreeHeaders(configs) }
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {loading ? (
                                            <tr>
                                            <td colSpan={configs.treelist.items.length + 1}>
                                                <div className="text-center mt-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                </div>
                                            </td>
                                            </tr>
                                        ) : internalRoot && internalRoot.id ? (
                                            // Aquí pintas tus filas del árbol
                                            <BasicTreeItem
                                            pitem={internalRoot}
                                            searchText={text}
                                            level={0}
                                            configs={configs}
                                            />
                                        ) : (
                                            <tr>
                                            <td colSpan={configs.treelist.items.length + 1}>
                                                <div className="text-center mt-2">
                                                {t(tc.ACCOUNT_TREE_PERIOD_EMPTY_LABEL)}
                                                </div>
                                            </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Fragment>
        
    )
}

export default BasicTree