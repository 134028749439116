import React from 'react'; 
import { useTranslation } from 'react-i18next';
import DocumentHeader from './document-header'

function TermsDocument() {
    const { t, i18n } = useTranslation();

    const commonStyles = {
        container: {
          maxWidth: '800px',
          margin: '0 auto',
          padding: '20px',
          lineHeight: '1.6',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
        header: {
          borderBottom: '2px solid #007bff',
          paddingBottom: '10px',
          marginBottom: '20px',
          color: '#007bff',
        },
        sectionHeader: {
          color: '#007bff',
          borderBottom: '1px solid #e0e0e0',
          paddingBottom: '10px',
        },
        link: {
          color: '#007bff',
          textDecoration: 'none',
        },
        list: {
          backgroundColor: '#f9f9f9',
          padding: '15px',
          borderRadius: '5px',
        }
    };
  
    const headerText = i18n.language === 'es' ? 'Términos y Condiciones de Uso' : 'Terms and Conditions of Use';

    const spanishContent = (
    <div style={commonStyles.container}>

        <h1 style={commonStyles.header}>Política de Términos y Condiciones de Uso</h1>

        <section>
        <h2 style={commonStyles.sectionHeader}>Introducción</h2>
        <p>
            Bienvenido a FGo360.com, al utilizar nuestros servicios, aceptas cumplir con los términos y condiciones descritos en este documento. Por favor, léelo detenidamente.
        </p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Definiciones</h2>
        <ul style={commonStyles.list}>
            <li><strong>Usuario:</strong> Cualquier persona que accede y utiliza los servicios de FGo360.com.</li>
            <li><strong>Servicios:</strong> Todas las funcionalidades y beneficios ofrecidos por FGo360.com.</li>
            <li><strong>Datos Personales:</strong> Información que puede identificar al usuario de manera individual, como nombre, dirección, correo electrónico, teléfono, etc.</li>
        </ul>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Condiciones de Uso</h2>
        
        <h3>3.1. Aceptación de los Términos</h3>
        <p>Al acceder y usar FGo360.com, aceptas los términos y condiciones aquí establecidos. Si no estás de acuerdo con alguna parte, no debes utilizar nuestros servicios.</p>
        
        <h3>3.2. Elegibilidad y Uso Permitido</h3>
        <ul style={commonStyles.list}>
            <li><strong>Acceso universal:</strong> Los servicios de FGo360.com están diseñados para ser aptos y accesibles para usuarios de todas las edades.</li>
            <li><strong>Menores de edad:</strong> Se recomienda que los menores de 18 años obtengan el consentimiento de sus padres o tutores antes de utilizar el servicio.</li>
            <li><strong>Responsabilidad parental:</strong> Se anima a los padres y tutores a supervisar el uso del servicio por parte de menores.</li>
            <li><strong>Contenido apropiado:</strong> Se busca mantener un entorno seguro, pero no se garantiza que todo el contenido generado por usuarios sea siempre adecuado para todas las edades.</li>
            <li><strong>Legislación local:</strong> El uso del servicio está sujeto a las leyes locales relacionadas con la edad del usuario.</li>
        </ul>
        <p>El usuario se compromete a no utilizar los servicios de FGo360.com para:</p>
        <ul style={commonStyles.list}>
            <li>Violar leyes o regulaciones aplicables.</li>
            <li>Infringir derechos de propiedad intelectual.</li>
            <li>Transmitir material dañino o malicioso.</li>
            <li>Interferir con el funcionamiento normal del servicio.</li>
        </ul>
        
        <h3>3.3. Registro de Cuenta</h3>
        <p>Para acceder a ciertos servicios, puede ser necesario crear una cuenta. El usuario es responsable de mantener la confidencialidad de sus credenciales y de todas las actividades en su cuenta.</p>

        <h3>3.4. Modificaciones de los Términos</h3>
        <p>FGo360.com se reserva el derecho de modificar estos términos en cualquier momento. El uso continuo del servicio después de dichas modificaciones constituye su aceptación.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Propiedad Intelectual</h2>
        <p>Todo el contenido en FGo360.com es propiedad de la empresa o sus proveedores y está protegido por leyes de propiedad intelectual.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Responsabilidades y Garantías</h2>
        <h3>5.1. Limitación de Responsabilidad</h3>
        <p>FGo360.com no será responsable por daños directos o indirectos derivados del uso del servicio.</p>
        <h3>5.2. Garantías</h3>
        <p>No garantizamos la ausencia de errores o interrupciones, pero nos esforzaremos por resolver problemas de manera eficiente.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Terminación</h2>
        <p>Nos reservamos el derecho de suspender o terminar el acceso a los servicios en caso de:</p>
        <ul style={commonStyles.list}>
            <li>Incumplimiento de los términos.</li>
            <li>Actividades ilegales o fraudulentas.</li>
            <li>Riesgo para otros usuarios o la plataforma.</li>
        </ul>
        <p>En caso de terminación injustificada, se podrá otorgar un reembolso proporcional.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Ley Aplicable y Jurisdicción</h2>
        <p>Estos términos se regirán por las leyes de Colombia y cualquier disputa será sometida a los tribunales colombianos.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Contacto</h2>
        <p>Si tienes alguna pregunta, puedes contactarnos en:</p>
        <p><strong>Nombre Empresa:</strong> FinanceGo SAS</p>
        <p><strong>Correo electrónico:</strong> <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a></p>
        <p><strong>Teléfono:</strong> +57 313 4536165</p>
        </section>
    </div>
    );

    const englishContent = (
        <div style={commonStyles.container}>
          <h1 style={commonStyles.header}>Terms and Conditions of Use Policy</h1>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Introduction</h2>
            <p>
              Welcome to FGo360.com. By using our services, you agree to comply with the terms and conditions described in this document. Please read it carefully.
            </p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Definitions</h2>
            <ul style={commonStyles.list}>
              <li><strong>User:</strong> Any person who accesses and uses the services of FGo360.com.</li>
              <li><strong>Services:</strong> All functionalities and benefits offered by FGo360.com.</li>
              <li><strong>Personal Data:</strong> Information that can individually identify the user, such as name, address, email, phone number, etc.</li>
            </ul>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Terms of Use</h2>
            
            <h3>3.1. Acceptance of Terms</h3>
            <p>By accessing and using FGo360.com, you accept the terms and conditions set forth herein. If you do not agree with any part, you should not use our services.</p>
            
            <h3>3.2. Eligibility and Permitted Use</h3>
            <ul style={commonStyles.list}>
              <li><strong>Universal Access:</strong> The services of FGo360.com are designed to be suitable and accessible for users of all ages.</li>
              <li><strong>Minors:</strong> It is recommended that minors under 18 obtain parental or guardian consent before using the service.</li>
              <li><strong>Parental Responsibility:</strong> Parents and guardians are encouraged to supervise minors' use of the service.</li>
              <li><strong>Appropriate Content:</strong> We strive to maintain a safe environment, but we do not guarantee that all user-generated content will always be suitable for all ages.</li>
              <li><strong>Local Legislation:</strong> Use of the service is subject to local laws related to the user's age.</li>
            </ul>
            <p>The user agrees not to use the services of FGo360.com to:</p>
            <ul style={commonStyles.list}>
              <li>Violate applicable laws or regulations.</li>
              <li>Infringe intellectual property rights.</li>
              <li>Transmit harmful or malicious material.</li>
              <li>Interfere with the normal operation of the service.</li>
            </ul>
            
            <h3>3.3. Account Registration</h3>
            <p>To access certain services, it may be necessary to create an account. The user is responsible for maintaining the confidentiality of their credentials and all activities in their account.</p>
      
            <h3>3.4. Modifications to the Terms</h3>
            <p>FGo360.com reserves the right to modify these terms at any time. Continued use of the service after such modifications constitutes acceptance.</p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Intellectual Property</h2>
            <p>All content on FGo360.com is the property of the company or its providers and is protected by intellectual property laws.</p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Liabilities and Warranties</h2>
            <h3>5.1. Limitation of Liability</h3>
            <p>FGo360.com will not be liable for direct or indirect damages resulting from the use of the service.</p>
            <h3>5.2. Warranties</h3>
            <p>We do not guarantee the absence of errors or interruptions, but we will strive to resolve issues efficiently.</p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Termination</h2>
            <p>We reserve the right to suspend or terminate access to services in case of:</p>
            <ul style={commonStyles.list}>
              <li>Violation of the terms.</li>
              <li>Illegal or fraudulent activities.</li>
              <li>Risk to other users or the platform.</li>
            </ul>
            <p>In case of unjustified termination, a proportional refund may be granted.</p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Applicable Law and Jurisdiction</h2>
            <p>These terms will be governed by the laws of Colombia, and any dispute will be submitted to Colombian courts.</p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Contact</h2>
            <p>If you have any questions, you can contact us at:</p>
            <p><strong>Company Name:</strong> FinanceGo SAS</p>
            <p><strong>Email:</strong> <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a></p>
            <p><strong>Phone:</strong> +57 313 4536165</p>
          </section>
        </div>
    );
      

    return (
        <div className="anonymous-container">
            <DocumentHeader headerText={headerText} />
            {i18n.language === 'es' ? spanishContent : englishContent}
        </div>
    );
}

export default TermsDocument; 