import React, {useEffect} from 'react'
import { Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';  // Importar ReactGA


import userRoutes from './user-authenticated-routes'
import chartRoutes from './chart-routes'
import reportRoutes from './report-routes'
import reportTypeRoutes from './report-type-routes'
import paymentMethodRoutes from './payment-method-routes'
import dashboardRoutes from './dashboard-routes'

import dashboardStandardRoutes from './dashboard-standard-routes'
import chartStandardRoutes from './chart-standard-routes'
import reportStandardRoutes from './report-standard-routes'

import transactionAutomatedEmailRoutes from  './transaction-automated-email-routes'
import transactionAutomatedSMSRoutes from  './transaction-automated-sms-routes'

import expenseBudgetRoutes from  './expense-budget-routes'

import vendorRoutes from  './vendor-routes'
import buyerRoutes from  './buyer-routes'

import userProfileRoutes from  './user-profile-routes'

import accountExpenseRoutes from  './account-expense-routes'
import accountIncomeRoutes from  './account-income-routes'
import accountAssetRoutes from  './account-asset-routes'
import accountLiabilityRoutes from  './account-liability-routes'

import transactionExpenseTemplateRoutes from  './transaction-expense-template-routes'
import transactionIncomeTemplateRoutes from  './transaction-income-template-routes'
import transactionAssetTemplateRoutes from  './transaction-asset-template-routes'
import transactionLiabilityPaymentTemplateRoutes from  './transaction-liability-payment-template-routes'
 
import userAutomatedEmailRoutes from  './user-automated-email-routes'
import expenseAutomatedEmailRoutes from  './automated-expense-email-routes'
import incomeAutomatedEmailRoutes from  './automated-income-email-routes'
import assetAutomatedEmailRoutes from  './automated-asset-email-routes'
import liabilityPaymentAutomatedEmailRoutes from  './automated-liability-payment-email-routes'


import transactionIncomeRoutes from  './transaction-income-routes'
import transactionExpenseRoutes from  './transaction-expense-routes'
import transactionAssetRoutes from  './transaction-asset-routes'
import transactionLiabilityRoutes from  './transaction-liability-routes'
import transactionLiabilityPaymentRoutes from  './transaction-liability-payment-routes'
import transactionReceiptRoutes from  './transation-receipt-routes'

import supportTicketRoutes from './support-ticket-routes'

import paymentRoutes from './payment-routes'
import payment2Routes from './payment2-routes'
 
import recordRoutes from './record-routes'
 
const NRoutes = (props) => {
  
    return (
        <Routes>
             
            { recordRoutes }

            { transactionIncomeRoutes }

            { transactionExpenseRoutes }

            { transactionAssetRoutes }
            
            { transactionLiabilityRoutes }

            { transactionLiabilityPaymentRoutes }

            { transactionReceiptRoutes }


            { transactionIncomeTemplateRoutes }

            { transactionExpenseTemplateRoutes }

            { transactionAssetTemplateRoutes }
  
            { transactionLiabilityPaymentTemplateRoutes }


            { userAutomatedEmailRoutes }
            
            { incomeAutomatedEmailRoutes }

            { expenseAutomatedEmailRoutes }

            { assetAutomatedEmailRoutes }

            { liabilityPaymentAutomatedEmailRoutes }

            { userRoutes }

            { chartRoutes }

            { reportRoutes }

            { reportTypeRoutes }

            { paymentMethodRoutes }

            { dashboardRoutes } 

            { chartStandardRoutes }

            { reportStandardRoutes }

            { dashboardStandardRoutes } 

            { vendorRoutes }

            { buyerRoutes }
 
            { expenseBudgetRoutes } 

            { userProfileRoutes } 
 
            { accountExpenseRoutes }

            { accountIncomeRoutes }

            { accountLiabilityRoutes }

            { accountAssetRoutes }

            

            { transactionAutomatedEmailRoutes }
            { transactionAutomatedSMSRoutes }


            { supportTicketRoutes }

            { paymentRoutes }
            { payment2Routes }
             
        </Routes>
    )
}

export default NRoutes