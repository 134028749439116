import React from 'react';
import ReactMarkdown from 'react-markdown';
import './chat-message.css';

const ChatMessage = ({ message, messages, index }) => {
  const showDateHeader = index === 0 || (
    messages[index - 1]?.timestamp && 
    message.timestamp && 
    new Date(messages[index - 1].timestamp).toDateString() !== 
    new Date(message.timestamp).toDateString()
  );

  return (
    <div className="message-wrapper">
      {showDateHeader && message.timestamp && (
        <div className="date-header-container">
          <span className="date-header">
            {new Date(message.timestamp).toLocaleDateString(undefined, {
              weekday: 'long',
              day: 'numeric',
              month: 'long'
            })}
          </span>
        </div>
      )}
      <div className={`message-container ${message.isUser ? 'user-container' : ''}`}>
        <div className={`message-bubble ${message.isUser ? 'user-message' : 'assistant-message'}`}>
          <ReactMarkdown>{message.content}</ReactMarkdown>
        </div>
        <div className={`message-footer ${message.isUser ? 'justify-end' : 'justify-start'}`}>
          <span className="timestamp">
            {new Date(message.timestamp).toLocaleTimeString([], { 
              hour: '2-digit',
              minute: '2-digit'
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
