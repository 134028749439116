import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentHeader from './document-header'

function PrivacyDocument() {

    const { t, i18n } = useTranslation();
    
    const commonStyles = {
        container: {
          maxWidth: '800px',
          margin: '0 auto',
          padding: '20px',
          lineHeight: '1.6',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
        header: {
          borderBottom: '2px solid #007bff',
          paddingBottom: '10px',
          marginBottom: '20px',
          color: '#007bff',
        },
        sectionHeader: {
          color: '#007bff',
          borderBottom: '1px solid #e0e0e0',
          paddingBottom: '10px',
        },
        link: {
          color: '#007bff',
          textDecoration: 'none',
        },
        list: {
          backgroundColor: '#f9f9f9',
          padding: '15px',
          borderRadius: '5px',
        }
    };
 
    const headerText = i18n.language === 'es' ? 'Política de Privacidad' : 'Privacy Policy';


    const spanishContent = (
        <div style={commonStyles.container}>
          
          <h1 style={commonStyles.header}>Política de Privacidad</h1>

          <section>
            <h2 style={commonStyles.sectionHeader}>Introducción</h2>
            <p>
              Bienvenido a FGo360.com, al utilizar nuestros servicios, aceptas cumplir nuestras políticas
              de privacidad y condiciones descritas en este documento. Por favor, léalo
              detenidamente.
            </p>
          </section>
    
          <section>
            <h2 style={commonStyles.sectionHeader}>Definiciones</h2>
            <ul style={commonStyles.list}>
              <li>
                <strong>Usuario:</strong> Cualquier persona que accede y utiliza los servicios de FGo360.com.
              </li>
              <li>
                <strong>Servicios:</strong> Todas las funcionalidades y beneficios ofrecidos por FGo360.com.
              </li>
              <li>
                <strong>Datos Personales:</strong> Información que puede identificar al usuario de manera
                individual, como nombre, dirección, correo electrónico, teléfono, etc.
              </li>
            </ul>
          </section>
    
          <section>
            <h2 style={commonStyles.sectionHeader}>Política de Datos Personales</h2>
    
            <h3>1.1. Recopilación de Datos</h3>
            <p>
              Recopilamos datos personales de los usuarios cuando se registran, utilizan nuestros
              servicios o interactúan con nosotros de cualquier manera. Los datos pueden incluir nombre,
              dirección de correo electrónico, número de teléfono, dirección postal, información de pago y
              otra información relevante de uso del servicio.
            </p>
    
            <h3>1.2. Uso de Datos</h3>
            <p>Usamos los datos personales para:</p>
            <ul style={commonStyles.list}>
              <li>Proporcionar y mejorar nuestros servicios.</li>
              <li>Comunicar novedades y ofertas.</li>
              <li>Procesar transacciones.</li>
              <li>Enviarle notificaciones relacionadas con el servicio.</li>
              <li>Personalizar su experiencia.</li>
              <li>Prevenir fraudes y mejorar la seguridad.</li>
              <li>Cumplir con obligaciones legales.</li>
              <li>Para fines administrativos.</li>
            </ul>
    
            <h3>1.3. Protección de Datos</h3>
            <p>
              FGo360.com implementa medidas de seguridad adecuadas para proteger los datos personales
              contra accesos no autorizados, alteraciones, divulgación o destrucción.
            </p>
    
            <h3>1.4. Compartir Datos con Terceros</h3>
            <p>
              No compartimos datos personales con terceros, excepto en los casos necesarios para cumplir
              con la ley, responder a procesos legales, proteger nuestros derechos o para operar nuestros
              servicios (como con proveedores de servicios de pago).
            </p>
          </section>
    
          <section>
            <h2 style={commonStyles.sectionHeader}>Derechos del Usuario</h2>
    
            <h3>2.1. Acceso y Corrección</h3>
            <p>
              Los usuarios tienen derecho a acceder y corregir sus datos personales en cualquier momento a
              través de su cuenta en FGo360.com.
            </p>
    
            <h3>2.2. Eliminación de Datos</h3>
            <p>
              Los usuarios pueden solicitar la eliminación de sus datos personales enviando una solicitud
              a <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a>. Procesaremos la solicitud de
              eliminación en un plazo razonable, sujeto a las obligaciones legales y administrativas.
            </p>
          </section>
    
          <section>
            <h2 style={commonStyles.sectionHeader}>Ley Aplicable y Jurisdicción</h2>
            <p>
              Estas políticas de datos se regirán e interpretarán de acuerdo con las leyes de Colombia.
              Cualquier disputa será sometida a la Jurisdicción exclusiva de los tribunales de Colombia.
            </p>
          </section>
    
          <section>
            <h2 style={commonStyles.sectionHeader}>Contacto</h2>
            <p>
              Si tienes alguna pregunta sobre las políticas de privacidad, puedes contactarnos en:
            </p>
            <p>
              <strong>Nombre Empresa:</strong> FinanceGo SAS
            </p>
            <p>
              <strong>Correo electrónico:</strong>{' '}
              <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a>
            </p>
            <p>
              <strong>Teléfono:</strong> +57 313 4536165
            </p>
          </section>
        </div>
    );
    
    const englishContent = (
        <div style={commonStyles.container}>

          <h1 style={commonStyles.header}>Privacy Policy</h1>

          <section>
            <h2 style={commonStyles.sectionHeader}>Introduction</h2>
            <p>
              Welcome to FGo360.com. By using our services, you agree to comply with our privacy policies 
              and the terms described in this document. Please read it carefully.
            </p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Definitions</h2>
            <ul style={commonStyles.list}>
              <li>
                <strong>User:</strong> Any person who accesses and uses the services of FGo360.com.
              </li>
              <li>
                <strong>Services:</strong> All functionalities and benefits offered by FGo360.com.
              </li>
              <li>
                <strong>Personal Data:</strong> Information that can individually identify the user, 
                such as name, address, email, phone number, etc.
              </li>
            </ul>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Personal Data Policy</h2>
      
            <h3>1.1. Data Collection</h3>
            <p>
              We collect users' personal data when they register, use our services, or interact with us in any way. 
              The data may include name, email address, phone number, mailing address, payment information, and other 
              relevant service usage details.
            </p>
      
            <h3>1.2. Data Usage</h3>
            <p>We use personal data to:</p>
            <ul style={commonStyles.list}>
              <li>Provide and improve our services.</li>
              <li>Communicate news and offers.</li>
              <li>Process transactions.</li>
              <li>Send service-related notifications.</li>
              <li>Personalize the user experience.</li>
              <li>Prevent fraud and enhance security.</li>
              <li>Comply with legal obligations.</li>
              <li>For administrative purposes.</li>
            </ul>
      
            <h3>1.3. Data Protection</h3>
            <p>
              FGo360.com implements appropriate security measures to protect personal data against unauthorized access, 
              alteration, disclosure, or destruction.
            </p>
      
            <h3>1.4. Data Sharing with Third Parties</h3>
            <p>
              We do not share personal data with third parties except when necessary to comply with the law, respond to 
              legal processes, protect our rights, or operate our services (such as with payment service providers).
            </p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>User Rights</h2>
      
            <h3>2.1. Access and Correction</h3>
            <p>
              Users have the right to access and correct their personal data at any time through their account on FGo360.com.
            </p>
      
            <h3>2.2. Data Deletion</h3>
            <p>
              Users may request the deletion of their personal data by sending a request to 
              <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a>. 
              We will process the deletion request within a reasonable timeframe, subject to legal and administrative obligations.
            </p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Applicable Law and Jurisdiction</h2>
            <p>
              These data policies shall be governed and interpreted in accordance with the laws of Colombia. 
              Any dispute shall be submitted to the exclusive jurisdiction of the courts of Colombia.
            </p>
          </section>
      
          <section>
            <h2 style={commonStyles.sectionHeader}>Contact</h2>
            <p>
              If you have any questions about the privacy policies, you can contact us at:
            </p>
            <p>
              <strong>Company Name:</strong> FinanceGo SAS
            </p>
            <p>
              <strong>Email:</strong>{' '}
              <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a>
            </p>
            <p>
              <strong>Phone:</strong> +57 313 4536165
            </p>
          </section>
        </div>
    );

  return (
    <div className="anonymous-container">
        <DocumentHeader headerText={headerText} />
        {i18n.language === 'es' ? spanishContent : englishContent}
    </div>
  );
}

export default PrivacyDocument;