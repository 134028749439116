
import * as gservice from './_generic-service'
 
import * as actions from '../redux/actions/chatbot-slice';

const SERVICE_PATH = 'finance-chats';
 
const sendMessage = async (params, dispatch) => {
 
    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.add(sparams, params, dispatch)
 
}

const getChatHistory = async (params, dispatch) => {
    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }
    return gservice.getAll(sparams, params, dispatch)
} 

const createNewChat = async (params, dispatch) => {
    const sparams = {
        path: SERVICE_PATH + '/newchat',
        actions: actions
    }
 
    return gservice.add(sparams, params, dispatch)
}

export { sendMessage, getChatHistory, createNewChat }