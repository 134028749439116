import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
 
import { DOCUMENT_WEB_DOCUMENTATION, DOCUMENT_APP_DOCUMENTATION } from '../common/configurations/constants'
import * as docservice from '../utilities/services/document-service'
import DocumentHeader from './document-header'
function HelpDocument() {
  
    const [document, setDocument] = useState(null);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch(); 

    const { device } = useParams()
   
    const headerText = i18n.language === 'es' ? 'Documentación' : 'Documentation';

    useEffect(() => {

        const loadDocument = async () => { 
            const response = await docservice.get({ 
                language: i18n.language, 
                name: device === 'web' ? DOCUMENT_WEB_DOCUMENTATION : DOCUMENT_APP_DOCUMENTATION 
            }, dispatch);
 
            if (response.success) {
                setDocument(response.data);
            } 
        }
       
        if (i18n.language) {
            loadDocument();
        }
   
    }, [i18n.language]);

    if(!document) {
        return <div>Loading...</div>
    }

    return (
       <div className="w-screen h-screen fixed top-0 left-0">
            <DocumentHeader headerText={headerText} />
            <iframe
                src={document.url}
                className="w-screen h-screen absolute top-0 left-0 border-none"
                style={{ width: "100vw", height: "100vh" }}
                title="Embedded PDF"
            ></iframe>
    </div>
    );
}

export default HelpDocument;