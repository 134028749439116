import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';  // Importar ReactGA

import userRoutes from './user-no-authenticated-routes'
import documentRoutes from './document-routes'

const NRoutes = (props) => {
  
    return (
        <Routes> 
            
            { userRoutes }

            { documentRoutes }

            <Route path="*"   element={<Navigate to='/' />}/>
              
        </Routes>
    )
}

export default NRoutes