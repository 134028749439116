import React from "react";
import * as c from "../configurations/constants";
 
import GenericSelector from './generic-selector'
import GenericNewSelector from './generic-new-selector'


// services
import * as accountExpenseService from '../../utilities/services/account-expense-service'
import * as accountAssetService from '../../utilities/services/account-asset-service'
import * as accountLiabilityService from '../../utilities/services/account-liability-service'
import * as accountIncomeService from '../../utilities/services/account-income-service'

import * as dashboardService from '../../utilities/services/dashboard-service'
import * as reportService from '../../utilities/services/report-service'
import * as chartService from '../../utilities/services/chart-service'
import * as dashboardStandardService from '../../utilities/services/dashboard-standard-service'
import * as reportStandardService from '../../utilities/services/report-standard-service'
import * as chartStandardService from '../../utilities/services/chart-standard-service'
import * as chartTypeService from '../../utilities/services/chart-type-service'

import * as dashboardTemplateService from '../../utilities/services/dashboard-template-service'
import * as reportTypeService from '../../utilities/services/report-type-service'
import * as reportTypeSchemaService from '../../utilities/services/report-type-schema-service' 
 
import * as templateLiabilityPaymentService from '../../utilities/services/template-liability-payment-service'
import * as templateAssetService from '../../utilities/services/template-asset-service'
import * as templateIncomeService from '../../utilities/services/template-income-service'
import * as templateExpenseService from '../../utilities/services/template-expense-service'
 
import * as paymentMethodService from '../../utilities/services/payment-method-service'

import * as accountService from '../../utilities/services/account-service'

import * as userProfileService from '../../utilities/services/user-profile-service'

import * as vendorService from '../../utilities/services/vendor-service'
import * as buyerService from '../../utilities/services/buyer-service'
 
import vendorConfigs from '../../third-parties/vendor/configs'
import buyerConfigs from '../../third-parties/buyer/configs'
  
const Selector = ({user, value, vConfig, onChange}) => {
    
    if(vConfig.context === c.SELECTOR_CONTEXT_MOVE_TARGET_INCOME)
        return (
            <GenericSelector service={accountIncomeService.getMoveTargets} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    if(vConfig.context === c.SELECTOR_CONTEXT_MOVE_TARGET_EXPENSE)
        return (
            <GenericSelector service={accountExpenseService.getMoveTargets} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    if(vConfig.context === c.SELECTOR_CONTEXT_MOVE_TARGET_LIABILITY)
        return (
            <GenericSelector service={accountLiabilityService.getMoveTargets} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    if(vConfig.context === c.SELECTOR_CONTEXT_MOVE_TARGET_ASSET)
        return (
            <GenericSelector service={accountAssetService.getMoveTargets} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_LIABILITY_PAYMENT_TEMPLATE)
        return (
            <GenericSelector service={templateLiabilityPaymentService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_ASSET_TEMPLATE)
        return (
            <GenericSelector service={templateAssetService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_INCOME_TEMPLATE)
        return (
            <GenericSelector  service={templateIncomeService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_REAL_LEAF_ACCOUNT)
        return (
            <GenericSelector service={accountService.getRealLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
        
    if(vConfig.context === c.SELECTOR_CONTEXT_ACTIVE_LEAF_ACCOUNT)
        return (
            <GenericSelector  service={accountAssetService.getLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    
    if(vConfig.context === c.SELECTOR_CONTEXT_ASSET_RECEIVABLE_LEAF_ACCOUNT)
        return (
            <GenericSelector  service={accountAssetService.getReceivableLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
  
    if(vConfig.context === c.SELECTOR_CONTEXT_PASSIVE_LEAF_ACCOUNT)
        return (
            <GenericSelector service={accountLiabilityService.getLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_LIABILITY_PAYABLE_LEAF_ACCOUNT)
        return (
            <GenericSelector service={accountLiabilityService.getPayableLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    
    if(vConfig.context === c.SELECTOR_CONTEXT_CURRENCY)
        return (
            <GenericSelector service={userProfileService.getCurrencies} user={user} value={value} onChange={(v) => onChange(v?.value, vConfig)} />
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_CURRENCY_DEFAULT_VALUE) {
        return (
            <GenericSelector service={userProfileService.getCurrencies} user={user} value={value? value: user.currency_id} onChange={(v) => onChange(v?.value, vConfig)} />
        )
    }
      
    if(vConfig.context === c.SELECTOR_CONTEXT_EXPENSE_TEMPLATE)
        return (
            <GenericSelector service={templateExpenseService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
        
    if(vConfig.context === c.SELECTOR_CONTEXT_USER_PROFILE_LANGUAGE)
        return (
            <GenericSelector service={userProfileService.getLanguages} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_USER_PROFILE_TIMEZONE)
        return (
            <GenericSelector service={userProfileService.getTimezones} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_INCOME_RECEIVER)
        return (
            <GenericSelector service={paymentMethodService.getTargetIncome} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_INCOME_LEAF_ACCOUNT)
        return (
            <GenericSelector service={accountIncomeService.getLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
 
    if(vConfig.context === c.SELECTOR_CONTEXT_EXPENSE_LEAF_ACCOUNT)
        return (
            <GenericSelector service={accountExpenseService.getLeaves} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_VENDOR)
        return (
            <GenericNewSelector service={vendorService.getNoLimitAll} serviceadd={vendorService.add} vconfigs={vendorConfigs} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    
    if(vConfig.context === c.SELECTOR_CONTEXT_BUYER)
        return (
            <GenericNewSelector service={buyerService.getNoLimitAll} serviceadd={buyerService.add} vconfigs={buyerConfigs} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    if(vConfig.context === c.SELECTOR_CONTEXT_PAYMENT_METHOD)
        return (
            <GenericSelector service={paymentMethodService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_DASHBOARD)
        return (
            <GenericSelector service={dashboardService.getNoLimitAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
        
    if(vConfig.context === c.SELECTOR_CONTEXT_REPORT)
        return (
            <GenericSelector service={reportService.getNoLimitAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
    if(vConfig.context === c.SELECTOR_CONTEXT_CHART)
        return (
            <GenericSelector service={chartService.getNoLimitAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_DASHBOARD_STANDARD)
        return (
            <GenericSelector service={dashboardStandardService.getNoLimitAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
        
    if(vConfig.context === c.SELECTOR_CONTEXT_REPORT_STANDARD)
        return (
            <GenericSelector service={reportStandardService.getNoLimitAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_CHART_STANDARD)
        return (
            <GenericSelector service={chartStandardService.getNoLimitAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )

    if(vConfig.context === c.SELECTOR_CONTEXT_CHART_TYPE)
        return (
            <GenericSelector service={chartTypeService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
 
    if(vConfig.context === c.SELECTOR_CONTEXT_REPORT_TYPE)
        return (
            <GenericSelector service={reportTypeService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
        
    if(vConfig.context === c.SELECTOR_CONTEXT_REPORT_TYPE_VIEW)
        return (
            <GenericSelector service={reportTypeSchemaService.getAll} user={user} value={value} onChange={(v)=> onChange(v?.value, vConfig)}/>
        )
  
    // default response
    return (
        <div />
    )
}

export default Selector