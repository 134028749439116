import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"
 
const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        } 
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'amount',
                        multicurrency: true,
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                        isCreatable: false,
                        isEditable: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'liability_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_PASSIVE_LEAF_ACCOUNT,
                        isSelectorLinked: true, //liabilityAccountConfig.object.basePath,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'asset_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_ACTIVE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_PAYMENT_METHOD,
                        isSelectorLinked: true, //assetAccountConfig.object.basePath,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'separator_space'
            },
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'interests',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INTERESTS,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        name: 'insurances',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INSURANCES,
                        isRequired: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'other_expenses',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_OTHER_EXPENSES,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        name: 'capital_payment',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_CAPITAL,
                        isCreatable: false,
                        isEditable: false
                    },
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-credit-card-2-back-fill transaction',
        color: cc.LIABILITY_PAYMENT_TRANSACTION
    },

    object: {
        name: 'transaction_liability_payment',
        label: tc.OBJECT_TRANSACTION_LIABILITY_PAYMENT_NAME_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_LIABILITY_PAYMENT_PLURAL_LABEL,
        basePath: 'liability-payments',
        sliceName: 'liabilityPayment',
        menuLabel: tc.NAV_MENU_TRANSACTION_LIABILITY_PAYMENT_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label:  tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            }, 
            {
                name: 'date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                isLinked: false
            },
            {
                name: 'amount',
                type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                isLinked: false
            }, 
            {
                ...elementTemplate,
                name: 'liability',
                label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE,
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    },

    relatedlist: [
        {
            label: tc.OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_NAME,
            name: 'transaction-receipt',
            parameters: {
                type: "payment"
            }
        }
    ] 
}
 
export default configs