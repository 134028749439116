import React from 'react';
import Chat from '../chat';
import * as service from '../../utilities/services/chatbot-help-service';

const HelpAssistanceChat = ({ show, close, user, dispatch }) => {
    return (
        <Chat
            show={show}
            onHide={close}
            service={service}
            user={user}
            dispatch={dispatch}
            welcomeMessage="helpAssistantWelcome"
            inputPlaceholder="helpAssistantPlaceholder"
            errorTranslationKey="helpAssistantError"
            title="helpAssistantTitle"
            useModal={true}
            modalClassName="help-assistance-modal"
        />
    );
};

export default HelpAssistanceChat;