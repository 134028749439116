import React from 'react'; 
import { useTranslation } from 'react-i18next';
import DocumentHeader from './document-header'

function TermsDocument() {
    const { t, i18n } = useTranslation();

    const commonStyles = {
        container: {
          maxWidth: '800px',
          margin: '0 auto',
          padding: '20px',
          lineHeight: '1.6',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
        header: {
          borderBottom: '2px solid #007bff',
          paddingBottom: '10px',
          marginBottom: '20px',
          color: '#007bff',
        },
        sectionHeader: {
          color: '#007bff',
          borderBottom: '1px solid #e0e0e0',
          paddingBottom: '10px',
        },
        link: {
          color: '#007bff',
          textDecoration: 'none',
        },
        list: {
          backgroundColor: '#f9f9f9',
          padding: '15px',
          borderRadius: '5px',
        }
    };
  
    const headerText = i18n.language === 'es' ? 'Instrucciones para eliminar tu cuenta' : 'Instructions to Delete Your Account';

    const spanishContent = (
    <div style={commonStyles.container}>

        <h1 style={commonStyles.header}>Instrucciones para eliminar tu cuenta</h1>

        <section>
        <h2 style={commonStyles.sectionHeader}>Introducción</h2>
        <p>
            Bienvenido a FGo360.com, al utilizar nuestros servicios, aceptas cumplir con los términos y condiciones descritos en este documento. Por favor, léelo detenidamente.
        </p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Definiciones</h2>
        <ul style={commonStyles.list}>
            <li><strong>Usuario:</strong> Cualquier persona que accede y utiliza los servicios de FGo360.com.</li>
            <li><strong>Servicios:</strong> Todas las funcionalidades y beneficios ofrecidos por FGo360.com.</li>
            <li><strong>Datos Personales:</strong> Información que puede identificar al usuario de manera individual, como nombre, dirección, correo electrónico, teléfono, etc.</li>
        </ul>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Condiciones de Uso</h2>
        
        <h3>3.1. Aceptación de los Términos</h3>
        <p>Al acceder y usar FGo360.com, aceptas los términos y condiciones aquí establecidos. Si no estás de acuerdo con alguna parte, no debes utilizar nuestros servicios.</p>
        
        <h3>3.2. Elegibilidad y Uso Permitido</h3>
        <ul style={commonStyles.list}>
            <li><strong>Acceso universal:</strong> Los servicios de FGo360.com están diseñados para ser aptos y accesibles para usuarios de todas las edades.</li>
            <li><strong>Menores de edad:</strong> Se recomienda que los menores de 18 años obtengan el consentimiento de sus padres o tutores antes de utilizar el servicio.</li>
            <li><strong>Responsabilidad parental:</strong> Se anima a los padres y tutores a supervisar el uso del servicio por parte de menores.</li>
            <li><strong>Contenido apropiado:</strong> Se busca mantener un entorno seguro, pero no se garantiza que todo el contenido generado por usuarios sea siempre adecuado para todas las edades.</li>
            <li><strong>Legislación local:</strong> El uso del servicio está sujeto a las leyes locales relacionadas con la edad del usuario.</li>
        </ul>
        <p>El usuario se compromete a no utilizar los servicios de FGo360.com para:</p>
        <ul style={commonStyles.list}>
            <li>Violar leyes o regulaciones aplicables.</li>
            <li>Infringir derechos de propiedad intelectual.</li>
            <li>Transmitir material dañino o malicioso.</li>
            <li>Interferir con el funcionamiento normal del servicio.</li>
        </ul>
        
        <h3>3.3. Registro de Cuenta</h3>
        <p>Para acceder a ciertos servicios, puede ser necesario crear una cuenta. El usuario es responsable de mantener la confidencialidad de sus credenciales y de todas las actividades en su cuenta.</p>

        <h3>3.4. Modificaciones de los Términos</h3>
        <p>FGo360.com se reserva el derecho de modificar estos términos en cualquier momento. El uso continuo del servicio después de dichas modificaciones constituye su aceptación.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Propiedad Intelectual</h2>
        <p>Todo el contenido en FGo360.com es propiedad de la empresa o sus proveedores y está protegido por leyes de propiedad intelectual.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Responsabilidades y Garantías</h2>
        <h3>5.1. Limitación de Responsabilidad</h3>
        <p>FGo360.com no será responsable por daños directos o indirectos derivados del uso del servicio.</p>
        <h3>5.2. Garantías</h3>
        <p>No garantizamos la ausencia de errores o interrupciones, pero nos esforzaremos por resolver problemas de manera eficiente.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Terminación</h2>
        <p>Nos reservamos el derecho de suspender o terminar el acceso a los servicios en caso de:</p>
        <ul style={commonStyles.list}>
            <li>Incumplimiento de los términos.</li>
            <li>Actividades ilegales o fraudulentas.</li>
            <li>Riesgo para otros usuarios o la plataforma.</li>
        </ul>
        <p>En caso de terminación injustificada, se podrá otorgar un reembolso proporcional.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Ley Aplicable y Jurisdicción</h2>
        <p>Estos términos se regirán por las leyes de Colombia y cualquier disputa será sometida a los tribunales colombianos.</p>
        </section>

        <section>
        <h2 style={commonStyles.sectionHeader}>Contacto</h2>
        <p>Si tienes alguna pregunta, puedes contactarnos en:</p>
        <p><strong>Nombre Empresa:</strong> FinanceGo SAS</p>
        <p><strong>Correo electrónico:</strong> <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a></p>
        <p><strong>Teléfono:</strong> +57 313 4536165</p>
        </section>
    </div>
    );

    const englishContent = (
        <div style={commonStyles.container}>

            <h1 style={commonStyles.header}>Instructions to Delete Your Account</h1>

            <section>
                <h2 style={commonStyles.sectionHeader}>Introduction</h2>
                <p>
                    At FGo360.com, we respect your right to decide about your account. If you wish to delete it, please follow the detailed instructions below.
                </p>
            </section>

            <section>
                <h2 style={commonStyles.sectionHeader}>Steps to Delete Your Account</h2>
                <ul style={commonStyles.list}>
                    <li>Open the FGo360.com mobile application.</li>
                    <li>Go to the <strong>Settings</strong> section by tapping the button in the upper right corner of the screen.</li>
                    <li>Scroll to the bottom of the menu.</li>
                    <li>Select the <strong>Delete Account</strong> option, highlighted in red.</li>
                    <li>Follow the on-screen instructions to confirm the deletion of your account.</li>
                </ul>
                <p><strong>Note:</strong> Once your account is deleted, your information cannot be recovered.</p>
            </section>

            <section>
                <h2 style={commonStyles.sectionHeader}>Contact</h2>
                <p>If you need further assistance, you can contact us at:</p>
                <p><strong>Company Name:</strong> FinanceGo SAS</p>
                <p><strong>Email:</strong> <a href="mailto:support@fgo360.com" style={commonStyles.link}>support@fgo360.com</a></p>
                <p><strong>Phone:</strong> +57 313 4536165</p>
            </section>

        </div>

    );
      

    return (
        <div className="anonymous-container">
            <DocumentHeader headerText={headerText} />
            {i18n.language === 'es' ? spanishContent : englishContent}
        </div>
    );
}

export default TermsDocument; 