import React from 'react';
import { useNavigate } from 'react-router-dom';
import './document-header.css';

function DocumentHeader({ headerText }) {

    const navigate = useNavigate();

    const commonStyles = {
        header: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#ffffff',
            textAlign: 'center',
            marginRight: '20px'
        }
    };
    
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid d-flex justify-content-between">
                <div className="navbar-brand" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                    <span>FGo360</span>
                </div>
                <div className="">
                    <h1 style={commonStyles.header}>{headerText}</h1>
                </div>
            </div>
        </nav>
    );
}

export default DocumentHeader;