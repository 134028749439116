import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import i18n from 'i18next'

const getStoredUser = () => {
    
    const user = Cookies.get('ut')
     
    try {
        return JSON.parse(user)
    }
    catch(e) {
        return undefined
    }
}
 
const userSlice = createSlice({
    name: 'user',
    initialState: { 
        loading: false, 
        tokenid: undefined, 
        user: getStoredUser(),
        rememberMe: false,
        message: undefined, 
        error: undefined 
    },
    reducers: {
        
        add(state, action) {
            state.loading = true
        },
        
        addResponse(state, action) {  
            state.loading = false
            state.tokenid = action.payload.id
        },
        
        login(state, action) {
            state.loading = true

            // clear localstorage 
            Cookies.remove('ut')
            state.rememberMe = action.payload.rememberMe
        },
        
        loginResponse(state, action) {
            state.loading = false
            state.tokenid = action.payload.id
        },
        
        activate(state, action) {
            state.loading = true
        },
        
        activateResponse(state, action) {
            
            state.loading = false
            
            if(action.payload) {
                 
                // cookie creation
                var expireDate = new Date();
                expireDate.setFullYear(expireDate.getFullYear() + 1);
 
                // Use Cookies.set with additional attributes for security
                Cookies.set('ut', JSON.stringify(action.payload), {
                    expires: expireDate, // Set the expiration date
                    secure: process.env.REACT_APP_NODE_ENV !== 'dev', // Changed logic
                    sameSite: process.env.REACT_APP_NODE_ENV === 'dev' ? 'Lax' : 'Strict', // Changed logic
                });

                state.user = action.payload

                // Change the language using i18n
                if (action.payload.language_code) {
                    i18n.changeLanguage(action.payload.language_code)
                }
            }
            
        },

        idProvider(state, action) {
            state.loading = true
        },
        
        idProviderResponse(state, action) {
            
            state.loading = false 
            if(action.payload) {
                state.user = action.payload
  
                // cookie creation
                var expireDate = new Date();
                expireDate.setFullYear(expireDate.getFullYear() + 1); // Sets the date to one year from today
  
                Cookies.set('ut', JSON.stringify(action.payload), {
                    expires: expireDate, // Set the expiration date
                    secure: process.env.REACT_APP_NODE_ENV !== 'dev', // Changed logic
                    sameSite: process.env.REACT_APP_NODE_ENV === 'dev' ? 'Lax' : 'Strict', // Changed logic
                });

                // Change the language using i18n
                if (action.payload.language_code) {
                    i18n.changeLanguage(action.payload.language_code)
                }
            }
            
        },
 
        logout(state, action) {
            state.loading = true
        },
        
        logoutResponse(state, action) {
            
            Cookies.remove('ut')
            
            state.loading = false
            state.user = undefined
        },

        premiumConversion(state, action) {
            state.loading = true
            state.user.is_premium = action.payload    // true or false based on the parameter
            
            // Update cookie with the modified user state
            Cookies.set('ut', JSON.stringify(state.user), {
                secure: process.env.REACT_APP_NODE_ENV !== 'dev',
                sameSite: process.env.REACT_APP_NODE_ENV === 'dev' ? 'Lax' : 'Strict',
            });
        },
         
        error(state, action) {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    add, addResponse,
    login, loginResponse,
    logout, logoutResponse,
    activate, activateResponse,
    idProvider, idProviderResponse,
    premiumConversion,
    error
} = userSlice.actions;

export default userSlice.reducer