// system imports
import axios from "axios";
import * as shelper from './helper'

import * as uactions from '../redux/actions/user-slice';

const API_URL = shelper.serverDomain();
const AUTHENTICATION_INVALID_CREDENTIALS = 'Usuario o contraseña incorrectos. Por favor, inténtalo de nuevo.'

const fns = (sparams) => {
    return {
        getNoLimitAll: async (params, dispatch) => {
            return getNoLimitAll(sparams, params, dispatch)
        },
        getAll: async (params, dispatch) => {
            return getAll(sparams, params, dispatch)
        },
        getMore: async (params, dispatch) => {
            return getMore(sparams, params, dispatch)
        },
        get: async (params, dispatch) => {
            return get(sparams, params, dispatch)
        },
        add: async (params, dispatch) => {
            return add(sparams, params, dispatch)
        },
        clone: async (params, dispatch) => {
            return clone(sparams, params, dispatch)
        },
        update: async (params, dispatch) => {
            return update(sparams, params, dispatch)
        },
        remove: async (params, dispatch) => {
            return remove(sparams, params, dispatch)
        }
    }
} 
 
const checkPremiumStatus = (response, params, dispatch) => {
  
    if (dispatch && 
        response.headers && 
        response.headers['x-user-premium'] !== undefined && 
        params.user?.is_premium !== (response.headers['x-user-premium'] === 'true')) {
        dispatch(uactions.premiumConversion(response.headers['x-user-premium'] === 'true'));
    }
}

const getAll = async (sparams, params, dispatch) => {
    try {
        dispatch(sparams.actions.getAll(params));
        const url = `${API_URL}/${sparams.path}${shelper.querystring(params.payload)}`
        const options = shelper.axiosBearerConfig(params)
        const response = await axios.get(url, options);
        
        checkPremiumStatus(response, params, dispatch);
        
        return getResponse(response, sparams.actions.getAllResponse, dispatch)
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }
}

const getNoLimitAll = async (sparams, params, dispatch) => {
 
    try {
         
        dispatch(sparams.actions.getAll(params));
   
        const url = `${API_URL}/${sparams.path}/n/unlimited`
       
        const options = shelper.axiosBearerConfig(params)
        
        const response = await axios.get(url, options);
        
        return getResponse(response, sparams.actions.getAllResponse, dispatch)
 
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }

}

const getMore = async (sparams, params, dispatch) => {

    try {
          
        dispatch(sparams.actions.getMore(params));
 
        const url = `${API_URL}/${sparams.path}${shelper.querystring(params.payload)}`
        const options = shelper.axiosBearerConfig(params)
       
        const response = await axios.get(url, options);
         
        return getResponse(response, sparams.actions.getMoreResponse, dispatch) 
 
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }
    
}

const get = async (sparams, params, dispatch) => {
    try { 
        if(dispatch)
            dispatch(sparams.actions.get({}));
        const url = `${API_URL}/${sparams.path}/${params.payload.id}`
        const options = shelper.axiosBearerConfig(params)
        const response = await axios.get(url, options);
        
        checkPremiumStatus(response, params, dispatch);
        
        return getResponse(response, sparams.actions.getResponse, dispatch)
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }
}

const add = async (sparams, params, dispatch) => {

    try {
         
        dispatch(sparams.actions.add(params));

        const url = `${API_URL}/${sparams.path}`
        const options = shelper.axiosBearerConfig(params)
         
        const xparams = params.payload
   
        const response = await axios.post(url, xparams, options);
  
        return getResponse(response, sparams.actions.addResponse, dispatch)
 
    }
    catch(e) {
        console.log(e)
        return getError(e, sparams.actions.error, dispatch)
    }
    
}

const clone = async (sparams, params, dispatch) => {

    try {
         
        const url = `${API_URL}/${sparams.path}/${params.payload.id}/clone`
        const options = shelper.axiosBearerConfig(params)
        
        const xparams = params.payload
 
        const response = await axios.post(url, xparams, options);
 
        return getResponse(response, null, dispatch)
 
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }
    
}
 
const update = async (sparams, params, dispatch) => {

    try {
        
        dispatch(sparams.actions.update(params));

        const url = `${API_URL}/${sparams.path}`
        const options = shelper.axiosBearerConfig(params)
        
        const xparams = params.payload
 
        const response = await axios.put(url, xparams, options);

        return getResponse(response, sparams.actions.updateResponse, dispatch)
 
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }
    
}

const remove = async (sparams, params, dispatch) => {

    try {
        
        dispatch(sparams.actions.remove(params));

        const url = `${API_URL}/${sparams.path}/${params.payload.id}`
        const options = shelper.axiosBearerConfig(params)
         
        const response = await axios.delete(url, options);
 
        return getResponse(response, sparams.actions.removeResponse, dispatch)
    }
    catch(e) {
        return getError(e, sparams.actions.error, dispatch)
    }
    
}
 
const getResponse = (response, action, dispatch) => {
  
    if(action && dispatch)
        dispatch(action(response.data));
 
    return {success: true, data: response.data}
}

const getError = (exception, action, dispatch) => {
    
    let message = ''
    if(exception.response && exception.response.status === 401 && dispatch) {
        dispatch(uactions.logoutResponse())
        message = AUTHENTICATION_INVALID_CREDENTIALS
    } else if(exception.response && exception.response.data) {
        message = exception.response.data
    } else if (exception.response && exception.response.message) {
        message = exception.response.message
    } else {
        message = exception.message
    }

    const eobj = {success: false, message: message}
    
    if(action && dispatch)
        dispatch(action(eobj.message));
    
    return eobj
}

export { fns, get, getNoLimitAll, getAll, getMore, add, update, clone, remove, getResponse, getError, checkPremiumStatus }