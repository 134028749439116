import React, { Fragment, useEffect, useState, useRef } from "react";
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Icon from '@mdi/react';
import { 
    mdiFaceAgent,
    mdiEmail,
    mdiPhone,
    mdiMenu,
    mdiAccount,
    mdiCogOutline,
    mdiStar,
    mdiLock
} from '@mdi/js';
import { useTranslation } from 'react-i18next'; 

import configs from '../../../utilities/configuration-container'
import * as tc from '../../../common/configurations/text-constants'
import * as uservice from '../../../utilities/services/user-service'
import * as docservice from '../../../utilities/services/document-service'
 
import './authenticated-container.css'; // Actualiza aquí tu CSS para que se parezca a landing-header.css
import logo from '../../../logo.png'
import MaterialIcon from "../../../common/icons/material-icon";
import HelpAssistanceChat from "../../../ai/help-assistance";
import FinanceAssistanceChat from "../../../ai/finance-assistance";

import { DOCUMENT_WEB_DOCUMENTATION } from "../../../common/configurations/constants";

/** ============================================================================
 *  Ejemplo de sub-componente Modal para "Contact Us"
 *  ============================================================================
 */
const ContactUsModal = ({ contactUsModal, setContactUsModal }) => {
    const { t } = useTranslation(); 

    return (
        <Modal show={contactUsModal} onHide={() => setContactUsModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="div">
                    <div className="hstack gap-3">
                        <Icon path={mdiFaceAgent} size={1} />
                        {t('contactUsTitle')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>{t('contactUsText')}</p>
                    <ol style={{listStyle: 'none'}}>
                        <li style={{paddingBottom: '20px'}}>
                            <strong><Icon path={mdiEmail} size={1} /></strong> {t('contactUsEmail')}
                        </li>
                        <li>
                            <strong><Icon path={mdiPhone} size={1} /></strong> {t('contactUsPhone')}
                        </li>
                    </ol>
                    <p>{t('contactUsClosing')}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-custom-secondary btn-sm" onClick={() => setContactUsModal(false)}>
                    {t('BUTTON_CLOSE_LABEL')}
                </button>
            </Modal.Footer>
        </Modal>
    )
}
  
/** ============================================================================
 *  Ejemplo de menú que se desplegará al hacer clic en el ícono de cuenta
 *  (adaptación sin usar <NavDropdown> pero manteniendo la lógica de dropdown)
 *  ============================================================================
 */
const ConfigurationNavDropdown = ({ user, navigate, dispatch, select }) => {
    const { t, i18n } = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [webDoc, setWebDoc] = useState('');
    const dropdownRef = useRef(null);
  
    const handleToggleDropdown = (e) => {
      e.stopPropagation();
      setShowDropdown((prev) => !prev);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    const internalSelect = (item) => {
        select(item);
        setShowDropdown(false);
    }
  
    const logout = async () => {
        await uservice.logout(user, dispatch); 
        navigate('/');
    }
 
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div className="dropdown config-menu-item-container" ref={dropdownRef}>
        <div 
          className="nav-link" 
          onClick={handleToggleDropdown}
          style={{ cursor: 'pointer' }}
        >
          <Icon path={mdiCogOutline} size={1} />
        </div>
  
        {showDropdown && (
            <ul className="dropdown-menu dropdown-menu-end show">
                {
                    !user.is_admin && !user.is_premium &&
                    <Fragment>
                        <li>
                            <Link 
                                to={`subscriptions/payment`}
                            className="dropdown-item"
                            onClick={() => setShowDropdown(false)}
                        >
                            {t('authenticated-container-header.suscribe')}
                        </Link>
                        </li>
                        <li><hr className="dropdown-divider" /></li>
                    </Fragment>
                }
                <li>
                <button onClick={() => internalSelect('financeassistance')} className="dropdown-item">
                    {t('financeAssistance')}
                </button>
                </li>
                
                {/* Divider */}
                <li><hr className="dropdown-divider" /></li>

                {/* Documentación */}
                <li>
                <button onClick={() => internalSelect('contact')} className="dropdown-item">
                    {t('contactUsTitle')}
                </button>
                </li>
                <li>
                <button onClick={() => internalSelect('helpassistance')} className="dropdown-item">
                    {t('helpAssistance')}
                </button>
                </li>
                <li>
                <a 
                    href={webDoc ? webDoc.url : '#'} 
                    target="_blank" 
                    rel="noreferrer"
                    className="dropdown-item"
                    onClick={(e) => { 
                        e.preventDefault(); 
                        window.open('/documents/help/web', '_blank');
                    }}
                >
                    {t('documentation')}
                </a>
                </li>

                {/* Divider */}
                <li><hr className="dropdown-divider" /></li>

                {/* Enlace a perfil de usuario */}
                <li>
                <Link 
                    to={`user-profiles/${user.profile_id}`}
                    className="dropdown-item"
                    onClick={() => setShowDropdown(false)}
                >
                    {t('profile')}
                </Link>
                </li>
                

                {/* Ejemplo condicional si NO es admin */}
                {!user.is_admin && (
                <li>
                    <Link 
                        to={`user-automated-emails`} 
                        className="dropdown-item"
                        onClick={() => setShowDropdown(false)}
                    >
                        {t('emails')}
                    </Link>
                </li>
                )}

                {/* Divider */}
                <li><hr className="dropdown-divider" /></li>

                
                {/* Logout */}
                <li>
                <button onClick={logout} className="dropdown-item">
                    {t('logout')}
                </button>
                </li>

                 {/* Divider */}
                 <li><hr className="dropdown-divider" /></li>

                 {user.is_premium &&
                    <li>
                        <Link to="/subscriptions/cancel" className="dropdown-item"
                        onClick={() => setShowDropdown(false)}>
                            {t('authenticated-container-header.unsuscribe')}
                        </Link>
                    </li>
                }
            </ul>
            )}
      </div>
    );
};

/** ============================================================================
 *  Menú personalizado (tipo dropdown) para tus configuraciones, tablas, etc.
 *  Se ejemplifica sin <NavDropdown>, usando clases de Bootstrap y lógica propia.
 *  ============================================================================
 */
const CustomBootstrapNavDropdown = ({ header, configs, select }) => {
    
    const [show, setShow] = useState(false);
    const dropdownRef = useRef(null);
    const { t } = useTranslation();
  
    // Toggle dropdown visibility
    const handleToggleDropdown = (e) => {
      e.stopPropagation();
      setShow((prev) => !prev);
    };

    const internalSelect = (item) => {
        select(item);
        setShow(false);
    }
  
    // Close dropdown if clicked outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    return (
      <li
        className={`nav-item dropdown ${show ? "show" : ""}`}
        ref={dropdownRef}
      >
        {/* Dropdown toggle */}
        <a
          className="nav-link"
          href="#!"
          onClick={handleToggleDropdown}
          aria-expanded={show}
          style={{ cursor: "pointer" }}
        >
          {t(header)}
        </a>
  
        {/* Dropdown menu */}
        <ul className={`dropdown-menu ${show ? "show" : ""}`}>
          {configs.map((c, index) => {
            if (!c) {
              // Render divider for null configs
              return <li key={`divider-${index}`}><hr className="dropdown-divider" /></li>;
            }
            return (
              <li key={index}>
                <Link
                  to={c.object.basePath}
                  className="dropdown-item d-flex align-items-center"
                  onClick={() => internalSelect(c)}
                >
                  <div className="d-flex align-items-center" style={{marginRight: "8px"}}>
                    <MaterialIcon obj={c.object.name} />
                  </div>
                  {t(c.object.pluralLabel)}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    );
};

/** ============================================================================
 *  Header principal con menús (tipo "navbar" bootstrap manual)
 *  ============================================================================
 */
const AuthenticatedContainerHeader = ({ user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();
  
    // Estados de modales y chats
    const [contactUsModal, setContactUsModal] = useState(false);
    const [showHelpChat, setShowHelpChat] = useState(false);
    const [showFinanceChat, setShowFinanceChat] = useState(false);
  
    const handleToggle = () => setExpanded(!expanded);
  
    const handleSelect = (item) => {
      setExpanded(false);
      if (item === 'contact') setContactUsModal(true);
      if (item === 'helpassistance') setShowHelpChat(true);
      if (item === 'financeassistance') setShowFinanceChat(true);
    };
  
    return (
      <Fragment>
        <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: '#287233' }}>
          <div className="container-fluid d-flex justify-content-between">
            {/* LOGO / BRAND */}
            <div
              className="navbar-brand"
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => navigate('home/')}
            >
              <span className="logo-container">FGo360</span>
            </div>
  
            {/* Botón Toggler (Vista Móvil) */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleToggle}
              style={{ border: 'none', backgroundColor: 'transparent' }}
            >
              <Icon path={mdiMenu} size={1} color="#FFFFFF" />
            </button>
  
            {/* Menú colapsable */}
            <div className={`collapse navbar-collapse  ${expanded ? 'show' : ''}`}>
              {/* Menús izquierda */}
              <ul className="navbar-nav me-auto mt-2 mt-lg-0">
                {user.is_admin && (
                   
                    <CustomBootstrapNavDropdown
                      header={tc.NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL}
                      configs={[
                        configs.transactionEmail,
                        configs.transactionSMS,
                        null,
                        configs.expenseEmail,
                        configs.incomeEmail,
                        null,
                        configs.assetEmail,
                        configs.liabilityPaymentEmail
                      ]}
                      select={handleSelect}
                    />
                  
                )}
                 
                  <CustomBootstrapNavDropdown
                    header={tc.NAV_SECTION_DATA_LABEL}
                    configs={[configs.report, configs.chart, configs.dashboard]}
                    select={handleSelect}
                  />
                 
                {user.is_admin && (
                  
                    <CustomBootstrapNavDropdown
                      header={tc.NAV_SECTION_DATA_SYSTEM_LABEL}
                      configs={[configs.reportType]}
                      select={handleSelect}
                    />
                  
                )}
                {!user.is_admin && (
                  
                    <CustomBootstrapNavDropdown
                      header={tc.NAV_SECTION_PAYMENT_METHODS_LABEL}
                      configs={[
                        configs.paymentMethodBank,
                        configs.paymentMethodCard,
                        configs.paymentMethodCash,
                        configs.paymentMethodWallet
                      ]}
                      select={handleSelect}
                    />
                  
                )}
                {!user.is_admin && (
                   
                    <CustomBootstrapNavDropdown
                      header={tc.NAV_MENU_EXPENSE_LABEL}
                      configs={[
                        configs.accountExpense,
                        configs.expense,
                        configs.expenseTemplate,
                        configs.vendor,
                        configs.expenseBudget
                      ]}
                      select={handleSelect}
                    />
                  
                )}
                {!user.is_admin && (
                  
                    <CustomBootstrapNavDropdown
                      header={tc.NAV_MENU_INCOME_LABEL}
                      configs={[
                        configs.accountIncome,
                        configs.income,
                        configs.incomeTemplate,
                        configs.buyer
                      ]}
                      select={handleSelect}
                    />
                  
                )}
                {!user.is_admin && (
                  
                    <CustomBootstrapNavDropdown
                      header={tc.NAV_MENU_ASSET_LIABILITY_LABEL}
                      configs={[
                        configs.accountAsset,
                        configs.accountLiability,
                        configs.asset,
                        configs.assetTemplate,
                        configs.liabilityPayment
                      ]}
                      select={handleSelect}
                    />
                   
                )}
                
              </ul>
              {/* Menú usuario (derecha) */}
              <div className="d-flex align-items-center gap-2">
                 
              <span className="badge bg-light text-dark d-flex align-items-center gap-1">
                     
                    {user.is_premium &&
                        <Icon path={mdiStar} size={1} color="#FFD700" />
                    } 
                    <span className="fw-semibold alias_text-large">{user.alias}</span>
                    {!user.is_premium && 
                        <Link to="/subscriptions/payment" className="text-decoration-none d-flex align-items-center gap-1">
                            <span className="fw-semibold alias_text-large">{t('authenticated-container-header.upgrade')}</span>
                        </Link>
                    }
                </span>

                {/* Dropdown Configuración / Sesión */}
                <ConfigurationNavDropdown user={user} dispatch={dispatch} navigate={navigate} select={handleSelect} />
              </div>
            </div>
          </div>
        </nav>
  
        {/* Modales y Chats */}
        <ContactUsModal contactUsModal={contactUsModal} setContactUsModal={setContactUsModal} />
        <HelpAssistanceChat show={showHelpChat} close={() => setShowHelpChat(false)} user={user} dispatch={dispatch} />
        <FinanceAssistanceChat show={showFinanceChat} close={() => setShowFinanceChat(false)} user={user} dispatch={dispatch} />
      </Fragment>
    );
};

export default AuthenticatedContainerHeader;