import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@mdi/react';
import { mdiSend, mdiPlus, mdiRobotExcited, mdiEraser } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import ChatMessage from './chat-message';
import { Modal } from 'react-bootstrap';

import './chat.css'

const Chat = ({
    messages = [],
    onSendMessage = () => {},
    isLoading = false,
    inputPlaceholder = 'chatbot.placeholder',
    className = '',
    style = {},
    maxLength = 500,
    historyLength = 4,
    service,
    user,
    dispatch,
    welcomeMessage,
    errorTranslationKey,
    show,
    onHide,
    title,
    modalSize = "lg",
    modalClassName = "",
    useModal = false
}) => {
    const { t } = useTranslation();
    const [inputMessage, setInputMessage] = useState('');
    const [chatMessages, setChatMessages] = useState(messages);
    const [chatId, setChatId] = useState(null);
    const [loading, setLoading] = useState(isLoading);
    const chatEndRef = useRef(null);
    const textareaRef = useRef(null);

    const newChat = async () => {
        setChatId(null);
        if (welcomeMessage) {
            setChatMessages([{ 
                type: 'bot', 
                content: t(welcomeMessage),
                timestamp: new Date().toISOString()
            }]);
        } else {
            setChatMessages([]);
        }
        
        await service.createNewChat({ user }, dispatch);
    };

    // Initialize chat history if service is provided
    useEffect(() => {
        const loadChatHistory = async () => {
            if (service?.getChatHistory) {
                const response = await service.getChatHistory({ user }, dispatch);
                if (response.success) {
                    setChatId(response.data.chatId || null);
                    if (response.data.messages?.length > 0) {
                        setChatMessages(response.data.messages);
                    } else if (welcomeMessage) {
                        setChatMessages([{ 
                            type: 'bot', 
                            content: t(welcomeMessage),
                            timestamp: new Date().toISOString()
                        }]);
                    }
                }
            }
        };

        if (service) {
            loadChatHistory();
        } else {
            setChatMessages(messages);
        }
    }, []);

    // Update local messages when prop changes
    useEffect(() => {
        if (!service) {
            setChatMessages(messages);
        }
    }, [messages, service]);

    // Update loading state when prop changes
    useEffect(() => {
        if (!service) {
            setLoading(isLoading);
        }
    }, [isLoading, service]);

    // Scroll to bottom effect
    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatMessages]);

    // Textarea height adjustment
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [inputMessage]);
  
    const  addMessage = (message) => {
        setChatMessages(prev => [...prev, 
            {
                ...message,
                timestamp: message.timestamp || new Date().toISOString()
            }]);
    }

    const handleSendMessage = async () => {
        const trimmedMessage = inputMessage.trim();
        if (!trimmedMessage) return;

        // Add user message immediately
        addMessage({ 
            type: 'user', 
            content: trimmedMessage,
            timestamp: new Date().toISOString()
        });
        setInputMessage('');
        setLoading(true);

        try {
            const response = await service.sendMessage({ 
                payload: { message: trimmedMessage, chatId }, 
                user 
            }, dispatch);
              
            if (response.success) {
                if (response.data.chatId) {
                    setChatId(response.data.chatId);
                }

                // Add bot response
                addMessage(response.data.message);
            }
        } catch (error) {
            console.error('Chat error:', error);
            addMessage({ 
                type: 'bot', 
                content: t(errorTranslationKey || 'chatbot.error')
               
            });
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const remainingChars = maxLength - inputMessage.length;
    const isNearLimit = remainingChars <= 50;

    const chatContent = (
        <div className={`chat-container ${className}`} style={{ ...style, height: '60vh' }}>
            <div className="chat-messages p-3 overflow-auto h-100">
                {chatMessages.map((message, index) => (
                    <ChatMessage
                        key={index}
                        message={{
                            ...message,
                            isUser: message.type === 'user',
                            timestamp: message.timestamp || new Date().toISOString()
                        }}
                        messages={chatMessages}
                        index={index}
                    />
                ))}
                {loading && (
                    <div className="d-flex justify-content-start mb-3">
                        <div className="message-bubble bot-message p-3 rounded-3">
                            <div className="typing-indicator">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={chatEndRef} />
            </div>

            <div className="chat-input-container p-3 bg-white border-top">
                <div className="d-flex gap-2 align-items-center">
                    <textarea
                        ref={textareaRef}
                        className="form-control"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        maxLength={maxLength}
                        onKeyPress={handleKeyPress}
                        placeholder={t(inputPlaceholder)}
                        style={{
                            resize: 'none',
                            minHeight: '40px',
                            maxHeight: '120px',
                        }}
                        rows="1"
                        aria-label={t('chatbot.input_aria_label')}
                    />
                    <button
                        className="btn btn-primary d-flex align-items-center justify-content-center"
                        onClick={handleSendMessage}
                        disabled={!inputMessage.trim() || loading}
                        style={{ minWidth: '46px' }}
                        aria-label={t('chatbot.send_aria_label')}
                    >
                        <Icon path={mdiSend} size={1} />
                    </button>
                </div>
                <div className={`text-end text-muted small ${isNearLimit ? 'text-danger' : ''}`}>
                    {t('chatbot.remaining_chars', { count: remainingChars })}
                </div>
            </div>
        </div>
    );

    if (!useModal) {
        return chatContent;
    }

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            className={`chat-modal ${modalClassName}`}
            size={modalSize}
        >
            <Modal.Header closeButton>
                <Modal.Title as="div">
                    <div className="d-flex align-items-center gap-2">
                        <Icon path={mdiRobotExcited} size={1} />
                        <span>{t(title)}</span>
                    </div>
                </Modal.Title>
                <Icon 
                    path={mdiEraser} 
                    size={1} 
                    className="info-icon cursor-pointer"
                    onClick={newChat}
                    title={t('chatbot.new_chat')}
                />
            </Modal.Header>
            
            <Modal.Body className="p-0">
                {chatContent}
            </Modal.Body> 
        </Modal>
    );
};

export default Chat;