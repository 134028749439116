import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"


import expenseAccountConfig from "../../accounts/account-expense/configs"
import assetAccountConfig from "../../accounts/account-asset/configs" 
import vendorConfig from '../../third-parties/vendor/configs'

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,
        
        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        schedule: {
            enable: true,
            handler: function() { console.log('schedule')}
        },
        addTemplateEntry: {
            enable: true,
            handler: function() { console.log('addTemplateEntry')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'expense_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_EXPENSE_LEAF_ACCOUNT,
                        isSelectorLinked: true, //expenseAccountConfig.object.basePath,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'payment_method_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_PAYMENT_METHOD,
                        isSelectorLinked: true, //assetAccountConfig.object.basePath,
                        isRequired: true,
                        
                    },
                    
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'vendor_id',
                        label: tc.OBJECT_VENDOR_LABEL,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_VENDOR,
                        isSelectorLinked: true, //vendorConfig.object.basePath,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-cart2 template',
        color: cc.EXPENSE_TEMPLATE
    },

    object: {
        name: 'transaction_expense_template',
        label: tc.OBJECT_TRANSACTION_TEMPLATE_EXPENSE_NAME_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_TEMPLATE_EXPENSE_PLURAL_LABEL,
        basePath: 'expense-templates',
        sliceName: 'expenseTemplate',
        menuLabel: tc.NAV_MENU_TRANSACTION_TEMPLATE_EXPENSE_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: 'text',
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'payment_method',
                type: 'text',
                label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD,
                isLinked: false,
                compactViewLeftDetail: true
            },
            {
                name: 'expense',
                type: 'text',
                label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE,
                isLinked: false
            },
            {
                name: 'amount',
                type: 'currency',
                label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                isLinked: false
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    } 
}
 
export default configs