import numeral from 'numeral';


const fdate = (d, user) => {
    if(!d)
        return ''

    try {
        let _date = new Date(d)
        var options = { dateStyle: 'full' };
        // var _resultDate = new Intl.DateTimeFormat('en-US', options).format(_date);
        var _resultDate = new Intl.DateTimeFormat('en-US').format(_date);

    }
    catch(e) {
        console.log(e)
        return 'N/A'
    }
    
    return _resultDate;
}

const fcurrency = (v, user) => {

    if(!v)
        return '-';
    
    return numeral(v).format('$0,0.00')
}

const formats = {
    fdate,
    fcurrency
}

export default formats