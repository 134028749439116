import React from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LanguageToggleComponent from '../language';
import './landing-header.css';

function LandingHeader({ t, i18n, handleFeaturesClick, handlePricingClick, handleContactClick, handleLogoClick, handleSignInClick }) {
    const navigate = useNavigate();

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid d-flex justify-content-between">
                    <div className="navbar-brand" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                        <span>FGo360</span>
                    </div>
                    <div className="d-flex align-items-center gap-1 menu-left d-none d-lg-flex">
                        <Nav className="me-0">
                            <Nav.Link 
                                href="#" 
                                onClick={handleFeaturesClick}
                                style={{ color: '#FFFFFF !important', textDecoration: 'none', marginRight: '2px' }}
                            >
                                {t('landing-page.features')}
                            </Nav.Link>

                            <Nav.Link 
                                href="#" 
                                onClick={handlePricingClick}
                                style={{ color: '#FFFFFF !important', textDecoration: 'none', marginRight: '2px' }}
                            >
                                {t('landing-page.pricing')}
                            </Nav.Link>
                            
                            <Nav.Link 
                                href="#" 
                                onClick={handleContactClick}
                                style={{ color: '#FFFFFF !important', textDecoration: 'none', marginRight: '2px' }}
                            >
                                {t('landing-page.contact')}
                            </Nav.Link>
                        </Nav>
                    </div>
                    <button 
                        className="btn btn-custom-outline ms-1" 
                        onClick={handleSignInClick}
                    >
                        {t('loginComponent.submit')}
                    </button> 
                </div>
            </nav>
            <div className="mobile-menu d-lg-none">
                <div className="container">
                    <div className="mobile-links">
                        <div className="mobile-link" onClick={handleFeaturesClick}>
                            {t('landing-page.features')}
                        </div>
                        <div className="mobile-link" onClick={handlePricingClick}>
                            {t('landing-page.pricing')}
                        </div>
                        <div className="mobile-link" onClick={handleContactClick}>
                            {t('landing-page.contact')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LandingHeader;