
export const BUTTON_EDIT_LABEL = 'BUTTON_EDIT_LABEL';
export const BUTTON_CLONE_LABEL = 'BUTTON_CLONE_LABEL';
export const BUTTON_ADD_LABEL = 'BUTTON_ADD_LABEL';
export const BUTTON_REMOVE_LABEL = 'BUTTON_REMOVE_LABEL';
export const BUTTON_SETUP_LABEL = 'BUTTON_SETUP_LABEL';
export const BUTTON_PREVIEW_LABEL = 'BUTTON_PREVIEW_LABEL';
export const BUTTON_REFURBISH_LABEL = 'BUTTON_REFURBISH_LABEL';
export const BUTTON_NO_APPLIED_LABEL = 'BUTTON_NO_APPLIED_LABEL';
export const BUTTON_REAL_ACCOUNT_PAYABLE_LABEL = 'BUTTON_REAL_ACCOUNT_PAYABLE_LABEL';
export const BUTTON_REAL_ACCOUNT_RECEIVABLE_LABEL = 'BUTTON_REAL_ACCOUNT_RECEIVABLE_LABEL';
export const BUTTON_REAL_ACCOUNT_LIABILITY_PAYMENT_LABEL = 'BUTTON_REAL_ACCOUNT_LIABILITY_PAYMENT_LABEL';
export const BUTTON_CHANGE_PASSWORD_LABEL = 'BUTTON_CHANGE_PASSWORD_LABEL';
export const BUTTON_CHANGE_ENABLE_MULTICURRENCY_LABEL = 'BUTTON_CHANGE_ENABLE_MULTICURRENCY_LABEL';
export const BUTTON_MOVE_ACCOUNT_LABEL = 'BUTTON_MOVE_ACCOUNT_LABEL';
export const BUTTON_NEW_EXPENSE_BUDGET_LABEL = 'BUTTON_NEW_EXPENSE_BUDGET_LABEL';
export const BUTTON_USER_EMAIL_VERIFY_LABEL = 'BUTTON_USER_EMAIL_VERIFY_LABEL';
export const SMALL_DEVICE_OPTION_LABEL = 'SMALL_DEVICE_OPTION_LABEL';
export const BUTTON_ADD_TEMPLATE_LABEL = 'BUTTON_ADD_TEMPLATE_LABEL';
export const BUTTON_ADD_TEMPLATE_ENTRY_LABEL = 'BUTTON_ADD_TEMPLATE_ENTRY_LABEL';
export const BUTTON_PRINT_INFO_LABEL = 'BUTTON_PRINT_INFO_LABEL';
export const BUTTON_SET_BALANCE_LABEL = 'BUTTON_SET_BALANCE_LABEL';
export const BUTTON_SCHEDULE_LABEL = 'BUTTON_SCHEDULE_LABEL';
export const BUTTON_CANCEL_LABEL = 'BUTTON_CANCEL_LABEL';
export const BUTTON_CLOSE_LABEL = 'BUTTON_CLOSE_LABEL';
export const BUTTON_SAVE_LABEL = 'BUTTON_SAVE_LABEL';
export const BUTTON_ENABLE_LABEL = 'BUTTON_ENABLE_LABEL';
export const BUTTON_CONVERT_LABEL = 'BUTTON_CONVERT_LABEL';
export const BUTTON_SAVENEW_LABEL = 'BUTTON_SAVENEW_LABEL';
export const BUTTON_NEW_CHILD_LABEL = 'BUTTON_NEW_CHILD_LABEL';
export const BUTTON_NEW_TRANSACTION_FROM_ACCOUNT_LABEL = 'BUTTON_NEW_TRANSACTION_FROM_ACCOUNT_LABEL';

// export const  BUTTON_EDIT_LABEL = 'Editar'
// export const  BUTTON_CLONE_LABEL = 'Clonar'
// export const  BUTTON_ADD_LABEL = 'Nuevo'
// export const  BUTTON_REMOVE_LABEL = 'Borrar'
// export const  BUTTON_SETUP_LABEL = 'Configurar'
// export const  BUTTON_PREVIEW_LABEL = 'Mostrar'
// export const  BUTTON_REFURBISH_LABEL = 'Renovar'
// export const  BUTTON_NO_APPLIED_LABEL = 'No Aplica'
// export const  BUTTON_REAL_ACCOUNT_PAYABLE_LABEL = 'Deuda'
// export const  BUTTON_REAL_ACCOUNT_RECEIVABLE_LABEL = 'Activo'
// export const  BUTTON_REAL_ACCOUNT_LIABILITY_PAYMENT_LABEL = 'Pago Deuda'
// export const  BUTTON_CHANGE_PASSWORD_LABEL = 'Cambio de Contraseña'
// export const  BUTTON_CHANGE_ENABLE_MULTICURRENCY_LABEL = 'Habilitar Modo MultiMoneda'
 
// export const  BUTTON_MOVE_ACCOUNT_LABEL = 'Mover'
  
// export const  BUTTON_NEW_EXPENSE_BUDGET_LABEL = 'Presupuesto'

// export const  BUTTON_USER_EMAIL_VERIFY_LABEL = 'Verificar Correo'
// export const  SMALL_DEVICE_OPTION_LABEL = 'Acciones'

// export const  BUTTON_ADD_TEMPLATE_LABEL = 'Plantilla'
// export const  BUTTON_ADD_TEMPLATE_ENTRY_LABEL = 'Transacción'
// export const  BUTTON_PRINT_INFO_LABEL = 'Visualizar'
// export const  BUTTON_SCHEDULE_LABEL = 'Programar'
// export const  BUTTON_CANCEL_LABEL = 'Cancelar'
// export const  BUTTON_CLOSE_LABEL = 'Cerrar'
// export const  BUTTON_SAVE_LABEL = 'Salvar'
// export const  BUTTON_ENABLE_LABEL = 'Habilitar'

// export const  BUTTON_CONVERT_LABEL = 'Convertir'
// export const  BUTTON_SAVENEW_LABEL = 'Salvar/Nuevo'
// export const  BUTTON_NEW_CHILD_LABEL = 'Subcategoría'
// export const  BUTTON_NEW_TRANSACTION_FROM_ACCOUNT_LABEL = 'Transacción'
 


export const OBJECT_FIELD_GENERIC_NAME = 'OBJECT_FIELD_GENERIC_NAME';
export const OBJECT_FIELD_GENERIC_DESCRIPTION = 'OBJECT_FIELD_GENERIC_DESCRIPTION';
export const OBJECT_FIELD_GENERIC_IS_MOBILE = 'OBJECT_FIELD_GENERIC_IS_MOBILE';
export const OBJECT_FIELD_GENERIC_CREATED_DATE = 'OBJECT_FIELD_GENERIC_CREATED_DATE';
export const OBJECT_FIELD_GENERIC_LASTUPDATE_DATE = 'OBJECT_FIELD_GENERIC_LASTUPDATE_DATE';

// transaction automated sms
export const OBJECT_TRANSACTION_AUTOMATED_SMS_LABEL = 'OBJECT_TRANSACTION_AUTOMATED_SMS_LABEL';
export const OBJECT_TRANSACTION_AUTOMATED_SMS_PLURAL_LABEL = 'OBJECT_TRANSACTION_AUTOMATED_SMS_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_STATUS = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_STATUS';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_BODY = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_BODY';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_MESSAGE = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_MESSAGE';

// transaction automated email
export const OBJECT_TRANSACTION_AUTOMATED_EMAIL_LABEL = 'OBJECT_TRANSACTION_AUTOMATED_EMAIL_LABEL';
export const OBJECT_TRANSACTION_AUTOMATED_EMAIL_PLURAL_LABEL = 'OBJECT_TRANSACTION_AUTOMATED_EMAIL_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_STATUS = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_STATUS';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_MESSAGE = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_MESSAGE';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY_HTML = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY_HTML';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_FROM = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_FROM';
export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_TO = 'OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_TO';

// -- automated email
export const OBJECT_FIELD_AUTOMATED_EMAIL_TEMPLATE = 'OBJECT_FIELD_AUTOMATED_EMAIL_TEMPLATE';
export const OBJECT_FIELD_AUTOMATED_EMAIL_AMOUNT = 'OBJECT_FIELD_AUTOMATED_EMAIL_AMOUNT';
export const OBJECT_FIELD_AUTOMATED_EMAIL_VENDOR = 'OBJECT_FIELD_AUTOMATED_EMAIL_VENDOR';
export const OBJECT_FIELD_AUTOMATED_EMAIL_BUYER = 'OBJECT_FIELD_AUTOMATED_EMAIL_BUYER';
export const OBJECT_FIELD_AUTOMATED_EMAIL_DATE = 'OBJECT_FIELD_AUTOMATED_EMAIL_DATE';
export const OBJECT_FIELD_AUTOMATED_EMAIL_TIME = 'OBJECT_FIELD_AUTOMATED_EMAIL_TIME';
export const OBJECT_FIELD_AUTOMATED_EMAIL_DATE_FORMAT = 'OBJECT_FIELD_AUTOMATED_EMAIL_DATE_FORMAT';
export const OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD = 'OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD';
export const OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD_TYPE = 'OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD_TYPE';
export const OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL = 'OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL';
export const OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL_VALIDATION = 'OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL_VALIDATION';
export const OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS = 'OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS';
export const OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS_OPTIONS = 'OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS_OPTIONS';

// user automated email
export const OBJECT_USER_AUTOMATED_EMAIL_LABEL = 'OBJECT_USER_AUTOMATED_EMAIL_LABEL';
export const OBJECT_USER_AUTOMATED_EMAIL_PLURAL_LABEL = 'OBJECT_USER_AUTOMATED_EMAIL_PLURAL_LABEL';
export const OBJECT_FIELD_USER_AUTOMATED_EMAIL = 'OBJECT_FIELD_USER_AUTOMATED_EMAIL';
export const OBJECT_FIELD_USER_AUTOMATED_VERIFIED = 'OBJECT_FIELD_USER_AUTOMATED_VERIFIED';

// expense automated email
export const OBJECT_EXPENSE_AUTOMATED_EMAIL_LABEL = 'OBJECT_EXPENSE_AUTOMATED_EMAIL_LABEL';
export const OBJECT_EXPENSE_AUTOMATED_EMAIL_PLURAL_LABEL = 'OBJECT_EXPENSE_AUTOMATED_EMAIL_PLURAL_LABEL';

// income automated email
export const OBJECT_INCOME_AUTOMATED_EMAIL_LABEL = 'OBJECT_INCOME_AUTOMATED_EMAIL_LABEL';
export const OBJECT_INCOME_AUTOMATED_EMAIL_PLURAL_LABEL = 'OBJECT_INCOME_AUTOMATED_EMAIL_PLURAL_LABEL';
export const OBJECT_FIELD_INCOME_AUTOMATED_ASSET = 'OBJECT_FIELD_INCOME_AUTOMATED_ASSET';

// asset automated email
export const OBJECT_ASSET_AUTOMATED_EMAIL_LABEL = 'OBJECT_ASSET_AUTOMATED_EMAIL_LABEL';
export const OBJECT_ASSET_AUTOMATED_EMAIL_PLURAL_LABEL = 'OBJECT_ASSET_AUTOMATED_EMAIL_PLURAL_LABEL';
export const OBJECT_FIELD_ASSET_AUTOMATED_ASSET = 'OBJECT_FIELD_ASSET_AUTOMATED_ASSET';

// liability payment automated email
export const OBJECT_LIABILITY_PAYMENT_AUTOMATED_EMAIL_LABEL = 'OBJECT_LIABILITY_PAYMENT_AUTOMATED_EMAIL_LABEL';
export const OBJECT_LIABILITY_PAYMENT_AUTOMATED_EMAIL_PLURAL_LABEL = 'OBJECT_LIABILITY_PAYMENT_AUTOMATED_EMAIL_PLURAL_LABEL';
export const OBJECT_FIELD_LIABILITY_PAYMENT_AUTOMATED_ASSET = 'OBJECT_FIELD_LIABILITY_PAYMENT_AUTOMATED_ASSET';
export const OBJECT_FIELD_LIABILITY_PAYMENT_AUTOMATED_LIABILITY = 'OBJECT_FIELD_LIABILITY_PAYMENT_AUTOMATED_LIABILITY';

// chart
export const OBJECT_CHART_LABEL = 'OBJECT_CHART_LABEL';
export const OBJECT_CHART_PLURAL_LABEL = 'OBJECT_CHART_PLURAL_LABEL';
export const OBJECT_FIELD_CHART_TYPE = 'OBJECT_FIELD_CHART_TYPE';
export const OBJECT_FIELD_CHART_REPORT_SOURCE = 'OBJECT_FIELD_CHART_REPORT_SOURCE';
export const OBJECT_FIELD_CHART_POSITION = 'OBJECT_FIELD_CHART_POSITION';
export const OBJECT_FIELD_CHART_DISPLAY = 'OBJECT_FIELD_CHART_DISPLAY';
export const OBJECT_FIELD_CHART_SETUP_LABEL = 'OBJECT_FIELD_CHART_SETUP_LABEL';
export const OBJECT_FIELD_CHART_SETUP_VALUE_1 = 'OBJECT_FIELD_CHART_SETUP_VALUE_1';
export const OBJECT_FIELD_CHART_SETUP_VALUE_2 = 'OBJECT_FIELD_CHART_SETUP_VALUE_2';

// chart standard
export const OBJECT_CHART_STANDARD_LABEL = 'OBJECT_CHART_STANDARD_LABEL';
export const OBJECT_CHART_STANDARD_PLURAL_LABEL = 'OBJECT_CHART_STANDARD_PLURAL_LABEL';

// data
export const OBJECT_FIELD_DATA_VISIBILITY = 'OBJECT_FIELD_DATA_VISIBILITY';
export const OBJECT_FIELD_DATA_DEFAULT_HOME = 'OBJECT_FIELD_DATA_DEFAULT_HOME';
export const OBJECT_FIELD_DATA_MOBILE_DEFAULT_HOME = 'OBJECT_FIELD_DATA_MOBILE_DEFAULT_HOME';


// report
export const OBJECT_REPORT_LABEL = 'OBJECT_REPORT_LABEL';
export const OBJECT_REPORT_PLURAL_LABEL = 'OBJECT_REPORT_PLURAL_LABEL';
export const OBJECT_FIELD_REPORT_TYPE = 'OBJECT_FIELD_REPORT_TYPE';


// report standard
export const OBJECT_REPORT_STANDARD_LABEL = 'OBJECT_REPORT_STANDARD_LABEL';
export const OBJECT_REPORT_STANDARD_PLURAL_LABEL = 'OBJECT_REPORT_STANDARD_PLURAL_LABEL';

// dashboard
export const OBJECT_DASHBOARD_LABEL = 'OBJECT_DASHBOARD_LABEL';
export const OBJECT_DASHBOARD_PLURAL_LABEL = 'OBJECT_DASHBOARD_PLURAL_LABEL';
export const OBJECT_FIELD_DASHBOARD_TEMPLATE = 'OBJECT_FIELD_DASHBOARD_TEMPLATE';
export const OBJECT_FIELD_DASHBOARD_NUMBER_OF_COLUMNS = 'OBJECT_FIELD_DASHBOARD_NUMBER_OF_COLUMNS';

// dashboard standard
export const OBJECT_DASHBOARD_STANDARD_LABEL = 'OBJECT_DASHBOARD_STANDARD_LABEL';
export const OBJECT_DASHBOARD_STANDARD_PLURAL_LABEL = 'OBJECT_DASHBOARD_STANDARD_PLURAL_LABEL';

// report types
export const OBJECT_REPORT_TYPE_LABEL = 'OBJECT_REPORT_TYPE_LABEL';
export const OBJECT_REPORT_TYPE_PLURAL_LABEL = 'OBJECT_REPORT_TYPE_PLURAL_LABEL';
export const OBJECT_FIELD_REPORT_TYPE_API_NAME = 'OBJECT_FIELD_REPORT_TYPE_API_NAME';

// payment method
export const OBJECT_FIELD_PAYMENT_METHOD_CODE1 = 'OBJECT_FIELD_PAYMENT_METHOD_CODE1';
export const OBJECT_FIELD_PAYMENT_METHOD_CODE2 = 'OBJECT_FIELD_PAYMENT_METHOD_CODE2';
export const OBJECT_FIELD_PAYMENT_METHOD_CODE3 = 'OBJECT_FIELD_PAYMENT_METHOD_CODE3';












 
// export const  OBJECT_FIELD_GENERIC_NAME = 'Nombre'
// export const  OBJECT_FIELD_GENERIC_DESCRIPTION = 'Descripcion'
// export const  OBJECT_FIELD_GENERIC_IS_MOBILE = 'Aplicacion Mobil'
// export const  OBJECT_FIELD_GENERIC_CREATED_DATE = 'Creación'
// export const  OBJECT_FIELD_GENERIC_LASTUPDATE_DATE = 'Actualización'

// // transaction automated sms
// export const OBJECT_TRANSACTION_AUTOMATED_SMS_LABEL = 'Transacción por SMS'
// export const OBJECT_TRANSACTION_AUTOMATED_SMS_PLURAL_LABEL = 'Transacciones por SMS'
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_STATUS = 'Estado'
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_BODY = 'Cuerpo'
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_SMS_MESSAGE = 'Mensaje'


// // transaction automated email
// export const OBJECT_TRANSACTION_AUTOMATED_EMAIL_LABEL = 'Transacción por Correo'
// export const OBJECT_TRANSACTION_AUTOMATED_EMAIL_PLURAL_LABEL = 'Transacciones por Correo'
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_STATUS = 'Estado'
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY = 'Cuerpo'
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_MESSAGE = 'Mensaje'

// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY_HTML = 'Cuerpo (HTML)'

// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_FROM = 'Remitente' 
// export const OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_TO = 'Destinatario' 

// // -- automated email 
// export const OBJECT_FIELD_AUTOMATED_EMAIL_TEMPLATE = 'Plantilla'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_AMOUNT = 'Valor'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_VENDOR = 'Vendedor'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_BUYER = 'Comprador'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_DATE = 'Fecha'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_TIME = 'Hora'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_DATE_FORMAT = 'Formato de Fecha'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD = 'Método de Pago'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD_TYPE = 'Tipo de Método de Pago'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL = 'Notificación'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL_VALIDATION = 'Validación de Notificación'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS = 'Clase'
// export const OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS_OPTIONS = 'Opciones de Clases'

 
// // user automated email
// export const OBJECT_USER_AUTOMATED_EMAIL_LABEL = 'Correo para Automatizar'
// export const OBJECT_USER_AUTOMATED_EMAIL_PLURAL_LABEL = 'Correos para Automatizar'
// export const OBJECT_FIELD_USER_AUTOMATED_EMAIL = 'Correo'
// export const OBJECT_FIELD_USER_AUTOMATED_VERIFIED = 'Verificado'


// // expense automated email
// export const OBJECT_EXPENSE_AUTOMATED_EMAIL_LABEL = 'Gasto Automatico'
// export const OBJECT_EXPENSE_AUTOMATED_EMAIL_PLURAL_LABEL = 'Gastos Automaticos'
 
// // income automated email
// export const OBJECT_INCOME_AUTOMATED_EMAIL_LABEL = 'Ingreso Automatico'
// export const OBJECT_INCOME_AUTOMATED_EMAIL_PLURAL_LABEL = 'Ingresos Automaticos'
// export const OBJECT_FIELD_INCOME_AUTOMATED_ASSET = 'Cuenta de Banco'
 
// // asset automated email
// export const OBJECT_ASSET_AUTOMATED_EMAIL_LABEL = 'Activo Automatico'
// export const OBJECT_ASSET_AUTOMATED_EMAIL_PLURAL_LABEL = 'Activos Automaticos'
// export const OBJECT_FIELD_ASSET_AUTOMATED_ASSET = 'Activo'



// // liability payment automated email
// export const OBJECT_LIABILITY_PAYMENT_AUTOMATED_EMAIL_LABEL = 'Pago de Deudas Automatico'
// export const OBJECT_LIABILITY_PAYMENT_AUTOMATED_EMAIL_PLURAL_LABEL = 'Pagos de Deudas Automaticos'
// export const OBJECT_FIELD_LIABILITY_PAYMENT_AUTOMATED_ASSET = 'Cuenta de Banco'
// export const OBJECT_FIELD_LIABILITY_PAYMENT_AUTOMATED_LIABILITY = 'Deuda'

// // chart
// export const OBJECT_CHART_LABEL = 'Gráfico'
// export const OBJECT_CHART_PLURAL_LABEL = 'Gráficos'
// export const OBJECT_FIELD_CHART_TYPE = 'Tipo'
// export const OBJECT_FIELD_CHART_REPORT_SOURCE = 'Reporte Fuente'
// export const OBJECT_FIELD_CHART_POSITION = 'Posición de Leyenda' 
// export const OBJECT_FIELD_CHART_DISPLAY = 'Mostrar Leyenda'
// export const OBJECT_FIELD_CHART_SETUP_LABEL = 'Etiqueta' 
// export const OBJECT_FIELD_CHART_SETUP_VALUE_1 = 'Valor 1'
// export const OBJECT_FIELD_CHART_SETUP_VALUE_2 = 'Valor 2'
 
// // chart
// export const OBJECT_CHART_STANDARD_LABEL = 'Gráfico de Sistema'
// export const OBJECT_CHART_STANDARD_PLURAL_LABEL = 'Gráficos de Sistema'

// // report
// export const OBJECT_REPORT_LABEL = 'Reporte'
// export const OBJECT_REPORT_PLURAL_LABEL = 'Reportes'
// export const OBJECT_FIELD_REPORT_TYPE = 'Tipo de Reporte'

// // report standard
// export const OBJECT_REPORT_STANDARD_LABEL = 'Reporte de Sistema'
// export const OBJECT_REPORT_STANDARD_PLURAL_LABEL = 'Reportes de Sistema'
 
// // dashboard
// export const OBJECT_DASHBOARD_LABEL = 'Panel'
// export const OBJECT_DASHBOARD_PLURAL_LABEL = 'Paneles'
// export const OBJECT_FIELD_DASHBOARD_TEMPLATE = 'Plantilla'
// export const OBJECT_FIELD_DASHBOARD_NUMBER_OF_COLUMNS = 'Columnas'


// // dashboard standard
// export const OBJECT_DASHBOARD_STANDARD_LABEL = 'Panel de Sistema'
// export const OBJECT_DASHBOARD_STANDARD_PLURAL_LABEL = 'Paneles de Sistema'

// // report types
// export const OBJECT_REPORT_TYPE_LABEL = 'Tipo de Reporte'
// export const OBJECT_REPORT_TYPE_PLURAL_LABEL = 'Tipos de Reportes'
// export const OBJECT_FIELD_REPORT_TYPE_API_NAME = 'Codigo'

// // payment method
// export const OBJECT_FIELD_PAYMENT_METHOD_CODE1 = '4 Ultimos Números'
// export const OBJECT_FIELD_PAYMENT_METHOD_CODE2 = '4 Ultimos Números (Tarjeta)'
// export const OBJECT_FIELD_PAYMENT_METHOD_CODE3 = 'Codigo 3'
 

// payment method - bank
export const OBJECT_PAYMENT_METHOD_BANK_LABEL = 'OBJECT_PAYMENT_METHOD_BANK_LABEL';
export const OBJECT_PAYMENT_METHOD_BANK_PLURAL_LABEL = 'OBJECT_PAYMENT_METHOD_BANK_PLURAL_LABEL';
export const OBJECT_FIELD_PAYMENT_METHOD_BANK_CODE1 = 'OBJECT_FIELD_PAYMENT_METHOD_BANK_CODE1';
export const OBJECT_FIELD_PAYMENT_METHOD_BANK_CODE2 = 'OBJECT_FIELD_PAYMENT_METHOD_BANK_CODE2';

// payment method - card
export const OBJECT_PAYMENT_METHOD_CARD_LABEL = 'OBJECT_PAYMENT_METHOD_CARD_LABEL';
export const OBJECT_PAYMENT_METHOD_CARD_PLURAL_LABEL = 'OBJECT_PAYMENT_METHOD_CARD_PLURAL_LABEL';
export const OBJECT_FIELD_PAYMENT_METHOD_CARD_CODE1 = 'OBJECT_FIELD_PAYMENT_METHOD_CARD_CODE1';


// payment method - wallet
export const OBJECT_PAYMENT_METHOD_WALLET_LABEL = 'OBJECT_PAYMENT_METHOD_WALLET_LABEL';
export const OBJECT_PAYMENT_METHOD_WALLET_PLURAL_LABEL = 'OBJECT_PAYMENT_METHOD_WALLET_PLURAL_LABEL';
export const OBJECT_FIELD_PAYMENT_METHOD_WALLET_CODE1 = 'OBJECT_FIELD_PAYMENT_METHOD_WALLET_CODE1';

// payment method - cash
export const OBJECT_PAYMENT_METHOD_CASH_LABEL = 'OBJECT_PAYMENT_METHOD_CASH_LABEL';
export const OBJECT_PAYMENT_METHOD_CASH_PLURAL_LABEL = 'OBJECT_PAYMENT_METHOD_CASH_PLURAL_LABEL';

// buyer
export const OBJECT_BUYER_LABEL = 'OBJECT_BUYER_LABEL';
export const OBJECT_BUYER_PLURAL_LABEL = 'OBJECT_BUYER_PLURAL_LABEL';
export const OBJECT_FIELD_BUYER_INCOME = 'OBJECT_FIELD_BUYER_INCOME';
export const OBJECT_FIELD_BUYER_TRANSACTION_INCOME_TEMPLATE = 'OBJECT_FIELD_BUYER_TRANSACTION_INCOME_TEMPLATE';
export const OBJECT_FIELD_BUYER_BALANCE_TODAY = 'OBJECT_FIELD_BUYER_BALANCE_TODAY';
export const OBJECT_FIELD_BUYER_BALANCE_WEEK = 'OBJECT_FIELD_BUYER_BALANCE_WEEK';
export const OBJECT_FIELD_BUYER_BALANCE_MONTH = 'OBJECT_FIELD_BUYER_BALANCE_MONTH';
export const OBJECT_FIELD_BUYER_BALANCE_YEAR = 'OBJECT_FIELD_BUYER_BALANCE_YEAR';
export const OBJECT_FIELD_BUYER_CODE = 'OBJECT_FIELD_BUYER_CODE';

// vendor
export const OBJECT_VENDOR_LABEL = 'OBJECT_VENDOR_LABEL';
export const OBJECT_VENDOR_PLURAL_LABEL = 'OBJECT_VENDOR_PLURAL_LABEL';
export const OBJECT_FIELD_VENDOR_EXPENSE = 'OBJECT_FIELD_VENDOR_EXPENSE';
export const OBJECT_FIELD_VENDOR_CODE = 'OBJECT_FIELD_VENDOR_CODE';
export const OBJECT_FIELD_VENDOR_BALANCE_TODAY = 'OBJECT_FIELD_VENDOR_BALANCE_TODAY';
export const OBJECT_FIELD_VENDOR_BALANCE_WEEK = 'OBJECT_FIELD_VENDOR_BALANCE_WEEK';
export const OBJECT_FIELD_VENDOR_BALANCE_MONTH = 'OBJECT_FIELD_VENDOR_BALANCE_MONTH';
export const OBJECT_FIELD_VENDOR_BALANCE_YEAR = 'OBJECT_FIELD_VENDOR_BALANCE_YEAR';

// account
export const OBJECT_FIELD_ACCOUNT_PARENT = 'OBJECT_FIELD_ACCOUNT_PARENT';
export const OBJECT_FIELD_ACCOUNT_BALANCE_TODAY = 'OBJECT_FIELD_ACCOUNT_BALANCE_TODAY';
export const OBJECT_FIELD_ACCOUNT_BUDGET_MONTH = 'OBJECT_FIELD_ACCOUNT_BUDGET_MONTH';
export const OBJECT_FIELD_ACCOUNT_BALANCE_WEEK = 'OBJECT_FIELD_ACCOUNT_BALANCE_WEEK';
export const OBJECT_FIELD_ACCOUNT_BALANCE_MONTH = 'OBJECT_FIELD_ACCOUNT_BALANCE_MONTH';
export const OBJECT_FIELD_ACCOUNT_BALANCE_YEAR = 'OBJECT_FIELD_ACCOUNT_BALANCE_YEAR';
export const OBJECT_FIELD_ACCOUNT_BALANCE = 'OBJECT_FIELD_ACCOUNT_BALANCE';
export const OBJECT_FIELD_ACCOUNT_INITIAL_BALANCE = 'OBJECT_FIELD_ACCOUNT_INITIAL_BALANCE';
export const OBJECT_FIELD_ACCOUNT_INITIAL_BALANCE_ORIGINAL = 'OBJECT_FIELD_ACCOUNT_INITIAL_BALANCE_ORIGINAL';

export const OBJECT_RELATED_LIST_ACCOUNT_DESCENDANTS = 'OBJECT_RELATED_LIST_ACCOUNT_DESCENDANTS';
export const OBJECT_RELATED_LIST_ACCOUNT_NAME = 'OBJECT_RELATED_LIST_ACCOUNT_NAME';
export const OBJECT_RELATED_LIST_ACCOUNT_LAST_TRANSACTIONS = 'OBJECT_RELATED_LIST_ACCOUNT_LAST_TRANSACTIONS';
export const OBJECT_RELATED_LIST_VENDOR_LAST_TRANSACTIONS = 'OBJECT_RELATED_LIST_VENDOR_LAST_TRANSACTIONS';
export const OBJECT_RELATED_LIST_BUYER_LAST_TRANSACTIONS = 'OBJECT_RELATED_LIST_BUYER_LAST_TRANSACTIONS';
export const OBJECT_RELATED_LIST_ACCOUNT_CALENDAR_LAST_TRANSACTIONS = 'OBJECT_RELATED_LIST_ACCOUNT_CALENDAR_LAST_TRANSACTIONS';
export const OBJECT_RELATED_LIST_SUPPORT_TICKET_ATTACHMENTS = 'OBJECT_RELATED_LIST_SUPPORT_TICKET_ATTACHMENTS';

// related list - receipts
export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_NAME = 'OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_NAME';
export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_GENERIC_PREFIX = 'OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_GENERIC_PREFIX';
export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_UPLOAD = 'OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_UPLOAD';
export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_SELECT = 'OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_SELECT';

// account - active
export const OBJECT_ACCOUNT_ACTIVE_LABEL = 'OBJECT_ACCOUNT_ACTIVE_LABEL';
export const OBJECT_ACCOUNT_ACTIVE_PLURAL_LABEL = 'OBJECT_ACCOUNT_ACTIVE_PLURAL_LABEL';

// account - passive
export const OBJECT_ACCOUNT_PASSIVE_LABEL = 'OBJECT_ACCOUNT_PASSIVE_LABEL';
export const OBJECT_ACCOUNT_PASSIVE_PLURAL_LABEL = 'OBJECT_ACCOUNT_PASSIVE_PLURAL_LABEL';

// account - expense
export const OBJECT_ACCOUNT_EXPENSE_LABEL = 'OBJECT_ACCOUNT_EXPENSE_LABEL';
export const OBJECT_ACCOUNT_EXPENSE_PLURAL_LABEL = 'OBJECT_ACCOUNT_EXPENSE_PLURAL_LABEL';

// account - income
export const OBJECT_ACCOUNT_INCOME_LABEL = 'OBJECT_ACCOUNT_INCOME_LABEL';
export const OBJECT_ACCOUNT_INCOME_PLURAL_LABEL = 'OBJECT_ACCOUNT_INCOME_PLURAL_LABEL';

// transaction
export const OBJECT_FIELD_TRANSACTION_DATE = 'OBJECT_FIELD_TRANSACTION_DATE';
export const OBJECT_FIELD_TRANSACTION_AMOUNT = 'OBJECT_FIELD_TRANSACTION_AMOUNT';
export const OBJECT_FIELD_TRANSACTION_AMOUNT_RATE = 'OBJECT_FIELD_TRANSACTION_AMOUNT_RATE';
export const OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL = 'OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL';
export const OBJECT_FIELD_TRANSACTION_TYPE = 'OBJECT_FIELD_TRANSACTION_TYPE';
export const OBJECT_FIELD_TRANSACTION_TAGS = 'OBJECT_FIELD_TRANSACTION_TAGS';

// expense transaction
export const OBJECT_TRANSACTION_EXPENSE_NAME_LABEL = 'OBJECT_TRANSACTION_EXPENSE_NAME_LABEL';
export const OBJECT_TRANSACTION_EXPENSE_PLURAL_LABEL = 'OBJECT_TRANSACTION_EXPENSE_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE = 'OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE';
export const OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD = 'OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD';

// income transaction
export const OBJECT_TRANSACTION_INCOME_NAME_LABEL = 'OBJECT_TRANSACTION_INCOME_NAME_LABEL';
export const OBJECT_TRANSACTION_INCOME_PLURAL_LABEL = 'OBJECT_TRANSACTION_INCOME_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_INCOME_INCOME = 'OBJECT_FIELD_TRANSACTION_INCOME_INCOME';
export const OBJECT_FIELD_TRANSACTION_INCOME_TARGET = 'OBJECT_FIELD_TRANSACTION_INCOME_TARGET';
export const OBJECT_FIELD_TRANSACTION_INCOME_HEALTH_INSURANCE = 'OBJECT_FIELD_TRANSACTION_INCOME_HEALTH_INSURANCE';
export const OBJECT_FIELD_TRANSACTION_INCOME_TAXES = 'OBJECT_FIELD_TRANSACTION_INCOME_TAXES';
export const OBJECT_FIELD_TRANSACTION_INCOME_RETIREMENT_PLANS = 'OBJECT_FIELD_TRANSACTION_INCOME_RETIREMENT_PLANS';
export const OBJECT_FIELD_TRANSACTION_INCOME_DEDUCTIONS = 'OBJECT_FIELD_TRANSACTION_INCOME_DEDUCTIONS';
export const OBJECT_FIELD_TRANSACTION_INCOME_OTHERS_DEDUCTIONS = 'OBJECT_FIELD_TRANSACTION_INCOME_OTHERS_DEDUCTIONS';
export const OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME = 'OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME';
export const OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME_ORIGINAL = 'OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME_ORIGINAL';

// liability transaction
export const OBJECT_TRANSACTION_LIABILITY_NAME_LABEL = 'OBJECT_TRANSACTION_LIABILITY_NAME_LABEL';
export const OBJECT_TRANSACTION_LIABILITY_PLURAL_LABEL = 'OBJECT_TRANSACTION_LIABILITY_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PASSIVE = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PASSIVE';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_ACTIVE = 'OBJECT_FIELD_TRANSACTION_LIABILITY_ACTIVE';

// liability payment transaction
export const OBJECT_TRANSACTION_LIABILITY_PAYMENT_NAME_LABEL = 'OBJECT_TRANSACTION_LIABILITY_PAYMENT_NAME_LABEL';
export const OBJECT_TRANSACTION_LIABILITY_PAYMENT_PLURAL_LABEL = 'OBJECT_TRANSACTION_LIABILITY_PAYMENT_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_ACTIVE = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_ACTIVE';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INTERESTS = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INTERESTS';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INSURANCES = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INSURANCES';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_CAPITAL = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_CAPITAL';
export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_OTHER_EXPENSES = 'OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_OTHER_EXPENSES';

// asset transaction
export const OBJECT_TRANSACTION_ASSET_NAME_LABEL = 'OBJECT_TRANSACTION_ASSET_NAME_LABEL';
export const OBJECT_TRANSACTION_ASSET_PLURAL_LABEL = 'OBJECT_TRANSACTION_ASSET_PLURAL_LABEL';
export const OBJECT_FIELD_TRANSACTION_ASSET_SOURCE = 'OBJECT_FIELD_TRANSACTION_ASSET_SOURCE';
export const OBJECT_FIELD_TRANSACTION_ASSET_ACTIVE = 'OBJECT_FIELD_TRANSACTION_ASSET_ACTIVE';

export const OBJECT_FIELD_TRANSACTION_ASSET_SOURCE_AMOUNT = 'OBJECT_FIELD_TRANSACTION_ASSET_SOURCE_AMOUNT';
export const OBJECT_FIELD_TRANSACTION_ASSET_DESTINATION_AMOUNT = 'OBJECT_FIELD_TRANSACTION_ASSET_DESTINATION_AMOUNT';
export const OBJECT_FIELD_TRANSACTION_ASSET_SOURCE_AMOUNT_FEE = 'OBJECT_FIELD_TRANSACTION_ASSET_SOURCE_AMOUNT_FEE';
export const OBJECT_FIELD_TRANSACTION_ASSET_DESTINATION_AMOUNT_FEE = 'OBJECT_FIELD_TRANSACTION_ASSET_DESTINATION_AMOUNT_FEE';
// templates
export const OBJECT_TRANSACTION_TEMPLATE_INCOME_NAME_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_INCOME_NAME_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_INCOME_PLURAL_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_INCOME_PLURAL_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_EXPENSE_NAME_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_EXPENSE_NAME_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_EXPENSE_PLURAL_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_EXPENSE_PLURAL_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_ASSET_NAME_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_ASSET_NAME_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_ASSET_PLURAL_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_ASSET_PLURAL_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_NAME_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_LIABILITY_NAME_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PLURAL_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PLURAL_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_NAME_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_NAME_LABEL';
export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_PLURAL_LABEL = 'OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_PLURAL_LABEL';

// expense budget
export const OBJECT_EXPENSE_BUDGET_NAME_LABEL = 'OBJECT_EXPENSE_BUDGET_NAME_LABEL';
export const OBJECT_EXPENSE_BUDGET_PLURAL_LABEL = 'OBJECT_EXPENSE_BUDGET_PLURAL_LABEL';
export const OBJECT_FIELD_EXPENSE_BUDGET_EXPENSE = 'OBJECT_FIELD_EXPENSE_BUDGET_EXPENSE';
export const OBJECT_FIELD_EXPENSE_BUDGET_WEEK = 'OBJECT_FIELD_EXPENSE_BUDGET_WEEK';
export const OBJECT_FIELD_EXPENSE_BUDGET_MONTH = 'OBJECT_FIELD_EXPENSE_BUDGET_MONTH';
export const OBJECT_FIELD_EXPENSE_BUDGET_YEAR = 'OBJECT_FIELD_EXPENSE_BUDGET_YEAR';

// schedule template
export const FUNCTION_SCHEDULE_TEMPLATE_HEADER = 'FUNCTION_SCHEDULE_TEMPLATE_HEADER';
export const FUNCTION_SCHEDULE_TEMPLATE_VALUE = 'FUNCTION_SCHEDULE_TEMPLATE_VALUE';
export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY = 'FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY';
export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_DAILY = 'FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_DAILY';
export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_MONTHLY = 'FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_MONTHLY';
export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_WEEKLY = 'FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_WEEKLY';
export const FUNCTION_SCHEDULE_TEMPLATE_NO_OPTION = 'FUNCTION_SCHEDULE_TEMPLATE_NO_OPTION';

// profile
export const OBJECT_PROFILE_NAME_LABEL = 'OBJECT_PROFILE_NAME_LABEL';
export const OBJECT_PROFILE_PLURAL_LABEL = 'OBJECT_PROFILE_PLURAL_LABEL';
export const OBJECT_FIELD_PROFILE_TIMEZONE = 'OBJECT_FIELD_PROFILE_TIMEZONE';
export const OBJECT_FIELD_PROFILE_HOME_DASHBOARD = 'OBJECT_FIELD_PROFILE_HOME_DASHBOARD';
export const OBJECT_FIELD_PROFILE_MOBILE_DASHBOARD = 'OBJECT_FIELD_PROFILE_MOBILE_DASHBOARD';
export const OBJECT_FIELD_PROFILE_CURRENCY = 'OBJECT_FIELD_PROFILE_CURRENCY';
export const OBJECT_FIELD_PROFILE_MULTICURRENCY = 'OBJECT_FIELD_PROFILE_MULTICURRENCY';
export const OBJECT_FIELD_PROFILE_LANGUAGE = 'OBJECT_FIELD_PROFILE_LANGUAGE';
export const OBJECT_FIELD_PROFILE_EMAIL = 'OBJECT_FIELD_PROFILE_EMAIL';

// support ticket
export const OBJECT_SUPPORT_TICKET_LABEL = 'OBJECT_SUPPORT_TICKET_LABEL';
export const OBJECT_SUPPORT_TICKET_PLURAL_LABEL = 'OBJECT_SUPPORT_TICKET_PLURAL_LABEL';
export const OBJECT_FIELD_SUPPORT_TICKET_NAME = 'OBJECT_FIELD_SUPPORT_TICKET_NAME';
export const OBJECT_FIELD_SUPPORT_TICKET_SUBJECT = 'OBJECT_FIELD_SUPPORT_TICKET_SUBJECT';
export const OBJECT_FIELD_SUPPORT_TICKET_STATUS = 'OBJECT_FIELD_SUPPORT_TICKET_STATUS';


// // payment method - wallet
// export const OBJECT_PAYMENT_METHOD_WALLET_LABEL = 'Billetera Digital'
// export const OBJECT_PAYMENT_METHOD_WALLET_PLURAL_LABEL = 'Billeteras Digitales'
// export const OBJECT_FIELD_PAYMENT_METHOD_WALLET_CODE1 = 'Código'

// // payment method - cash
// export const OBJECT_PAYMENT_METHOD_CASH_LABEL = 'Cartera de Efectivo'
// export const OBJECT_PAYMENT_METHOD_CASH_PLURAL_LABEL = 'Carteras de Efectivo'

// // buyer
// export const OBJECT_BUYER_LABEL = 'Empleador / Pagador'
// export const OBJECT_BUYER_PLURAL_LABEL = 'Empleadores / Pagadores'
// export const OBJECT_FIELD_BUYER_INCOME = 'Ingreso Enlazado'
// export const OBJECT_FIELD_BUYER_TRANSACTION_INCOME_TEMPLATE = 'Plantilla para Deducciones'
// export const OBJECT_FIELD_BUYER_BALANCE_TODAY = 'Mov.Hoy'
// export const OBJECT_FIELD_BUYER_BALANCE_WEEK = 'Mov.Semana'
// export const OBJECT_FIELD_BUYER_BALANCE_MONTH = 'Mov.Mes'
// export const OBJECT_FIELD_BUYER_BALANCE_YEAR = 'Mov.Año'
// export const OBJECT_FIELD_BUYER_CODE = 'Codigo'

// // vendor
// export const OBJECT_VENDOR_LABEL = 'Vendedor'
// export const OBJECT_VENDOR_PLURAL_LABEL = 'Vendedores'
// export const OBJECT_FIELD_VENDOR_EXPENSE = 'Categoría de Gasto por Defecto'
// export const OBJECT_FIELD_VENDOR_CODE = 'Codigo'
// export const OBJECT_FIELD_VENDOR_BALANCE_TODAY = 'Mov.Hoy'
// export const OBJECT_FIELD_VENDOR_BALANCE_WEEK = 'Mov.Semana'
// export const OBJECT_FIELD_VENDOR_BALANCE_MONTH = 'Mov.Mes'
// export const OBJECT_FIELD_VENDOR_BALANCE_YEAR = 'Mov.Año'

// // account
// export const OBJECT_FIELD_ACCOUNT_PARENT = 'Padre'
// export const OBJECT_FIELD_ACCOUNT_BALANCE_TODAY = 'Mov.Hoy'
// export const OBJECT_FIELD_ACCOUNT_BUDGET_MONTH = 'Presupuesto'
// export const OBJECT_FIELD_ACCOUNT_BALANCE_WEEK = 'Mov.Semana'
// export const OBJECT_FIELD_ACCOUNT_BALANCE_MONTH = 'Mov.Mes'
// export const OBJECT_FIELD_ACCOUNT_BALANCE_YEAR = 'Mov.Año'
// export const OBJECT_FIELD_ACCOUNT_BALANCE = 'Balance'
// export const OBJECT_FIELD_ACCOUNT_INITIAL_BALANCE = 'Balance Inicial'
// export const OBJECT_FIELD_ACCOUNT_INITIAL_BALANCE_ORIGINAL = 'Balance Inicial'

// export const OBJECT_RELATED_LIST_ACCOUNT_DESCENDANTS = 'Subcategorias'
// export const OBJECT_RELATED_LIST_ACCOUNT_NAME = 'Cuenta'

// export const OBJECT_RELATED_LIST_ACCOUNT_LAST_TRANSACTIONS = 'Ultimas 10 Transacciones'
// export const OBJECT_RELATED_LIST_VENDOR_LAST_TRANSACTIONS = 'Ultimas 10 Transacciones'
// export const OBJECT_RELATED_LIST_BUYER_LAST_TRANSACTIONS = 'Ultimas 10 Transacciones'
// export const OBJECT_RELATED_LIST_ACCOUNT_CALENDAR_LAST_TRANSACTIONS = 'Calendario'
// export const OBJECT_RELATED_LIST_SUPPORT_TICKET_ATTACHMENTS = 'Ficheros'


// // related list - receipts
// export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_NAME = 'Recibos'
// export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_GENERIC_PREFIX = 'Soporte'
// export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_UPLOAD = 'Subir'
// export const OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_SELECT = 'Seleccionar'



// // account - active
// export const OBJECT_ACCOUNT_ACTIVE_LABEL = 'Categoría de Activo'
// export const OBJECT_ACCOUNT_ACTIVE_PLURAL_LABEL = 'Categorias de Activos'

// // account - passive
// export const OBJECT_ACCOUNT_PASSIVE_LABEL = 'Categoría de Deuda'
// export const OBJECT_ACCOUNT_PASSIVE_PLURAL_LABEL = 'Categorias de Deudas'

// // account - active
// export const OBJECT_ACCOUNT_EXPENSE_LABEL = 'Categoría de Gasto'
// export const OBJECT_ACCOUNT_EXPENSE_PLURAL_LABEL = 'Categorias de Gastos'

// // account - active
// export const OBJECT_ACCOUNT_INCOME_LABEL = 'Categoría de Ingreso'
// export const OBJECT_ACCOUNT_INCOME_PLURAL_LABEL = 'Categorias de Ingresos'
  
// // transaction
// export const OBJECT_FIELD_TRANSACTION_DATE = 'Fecha'
// export const OBJECT_FIELD_TRANSACTION_AMOUNT = 'Monto'
// export const OBJECT_FIELD_TRANSACTION_AMOUNT_RATE = 'Tasa de Cambio'
// export const OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL = 'Monto'

// export const OBJECT_FIELD_TRANSACTION_TYPE = 'Tipo'
// export const OBJECT_FIELD_TRANSACTION_TAGS = 'Etiquetas'

// // expense transaction
// export const OBJECT_TRANSACTION_EXPENSE_NAME_LABEL = 'Transacción de Gasto'
// export const OBJECT_TRANSACTION_EXPENSE_PLURAL_LABEL = 'Transacciones de Gastos'
// export const OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE = 'Categoría de Gasto'
// export const OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD = 'Método de Pago'

// // income transaction
// export const OBJECT_TRANSACTION_INCOME_NAME_LABEL = 'Transacción de Ingreso'
// export const OBJECT_TRANSACTION_INCOME_PLURAL_LABEL = 'Transacciones de Ingresos'
// export const OBJECT_FIELD_TRANSACTION_INCOME_INCOME = 'Categoría de Ingreso'
// export const OBJECT_FIELD_TRANSACTION_INCOME_TARGET = 'Banco o Efectivo'
// export const OBJECT_FIELD_TRANSACTION_INCOME_HEALTH_INSURANCE = 'Seguro de Salud (Categoría de Gasto)'
// export const OBJECT_FIELD_TRANSACTION_INCOME_TAXES = 'Impuestos (Categoría de Gasto)'
// export const OBJECT_FIELD_TRANSACTION_INCOME_RETIREMENT_PLANS = 'Planes de Retiro (Categoría de Activo)'
// export const OBJECT_FIELD_TRANSACTION_INCOME_DEDUCTIONS = 'Deducciones de Nomina'
// export const OBJECT_FIELD_TRANSACTION_INCOME_OTHERS_DEDUCTIONS = 'Otras Deducciones de Nomina'

// export const OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME = 'Monto Consignado'
// export const OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME_ORIGINAL = 'Monto Consignado'


// // liability transaction
// export const OBJECT_TRANSACTION_LIABILITY_NAME_LABEL = 'Movimiento de Deuda'
// export const OBJECT_TRANSACTION_LIABILITY_PLURAL_LABEL = 'Movimientos de Deudas'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PASSIVE = 'Categoría de Deuda'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_ACTIVE = 'Categoría de Activo'

// // liability payment transaction
// export const OBJECT_TRANSACTION_LIABILITY_PAYMENT_NAME_LABEL = 'Registro de Pago de Deuda'
// export const OBJECT_TRANSACTION_LIABILITY_PAYMENT_PLURAL_LABEL = 'Registros de Pagos de Deudas'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE = 'Categoría de Deuda'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_ACTIVE = 'Método de Pago'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INTERESTS = 'Intereses'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INSURANCES = 'Seguros'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_CAPITAL = 'Pago a Capital'
// export const OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_OTHER_EXPENSES = 'Otros Gastos'


// // asset transaction
// export const OBJECT_TRANSACTION_ASSET_NAME_LABEL = 'Registro de Activo / Deuda'
// export const OBJECT_TRANSACTION_ASSET_PLURAL_LABEL = 'Registros de Activos / Deudas'
// export const OBJECT_FIELD_TRANSACTION_ASSET_SOURCE = 'Origen (Deuda, Banco o Efectivo)'
// export const OBJECT_FIELD_TRANSACTION_ASSET_ACTIVE = 'Destino (Categoría de Activo)'


// // templates

// // -- income template
// export const OBJECT_TRANSACTION_TEMPLATE_INCOME_NAME_LABEL = 'Plantilla de Ingreso'
// export const OBJECT_TRANSACTION_TEMPLATE_INCOME_PLURAL_LABEL = 'Plantillas de Ingresos'

// // -- expense template
// export const OBJECT_TRANSACTION_TEMPLATE_EXPENSE_NAME_LABEL = 'Plantilla de Gasto'
// export const OBJECT_TRANSACTION_TEMPLATE_EXPENSE_PLURAL_LABEL = 'Plantillas de Gasto'

// // -- asset template
// export const OBJECT_TRANSACTION_TEMPLATE_ASSET_NAME_LABEL = 'Plantilla de Registro de Activo / Deuda'
// export const OBJECT_TRANSACTION_TEMPLATE_ASSET_PLURAL_LABEL = 'Plantillas de Registros de Activos / Deudas'

// // -- liability template
// export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_NAME_LABEL = 'Plantilla de Deuda'
// export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PLURAL_LABEL = 'Plantillas de Deuda'

// // -- liability payment template
// export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_NAME_LABEL = 'Plantilla de Registro de Pago'
// export const OBJECT_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_PLURAL_LABEL = 'Plantillas de Registros de Pagos'
 
// // expense budget
// export const OBJECT_EXPENSE_BUDGET_NAME_LABEL = 'Presupuesto de Gasto'
// export const OBJECT_EXPENSE_BUDGET_PLURAL_LABEL = 'Presupuestos de Gastos'
// export const OBJECT_FIELD_EXPENSE_BUDGET_EXPENSE = 'Categoría de Gasto'
// export const OBJECT_FIELD_EXPENSE_BUDGET_WEEK = 'Semana'
// export const OBJECT_FIELD_EXPENSE_BUDGET_MONTH = 'Monto Mesual'
// export const OBJECT_FIELD_EXPENSE_BUDGET_YEAR = 'Monto Anual'

// // schedule template
// export const FUNCTION_SCHEDULE_TEMPLATE_HEADER = 'Programacion de Template'
// export const FUNCTION_SCHEDULE_TEMPLATE_VALUE = 'Valor'
// export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY = 'Frecuencia'
// export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_DAILY = 'Diario'
// export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_MONTHLY = 'Mensual'
// export const FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_WEEKLY = 'Semanal'
// export const FUNCTION_SCHEDULE_TEMPLATE_NO_OPTION = '- Seleccione -'

// // profile
// export const OBJECT_PROFILE_NAME_LABEL = 'Perfil'
// export const OBJECT_PROFILE_PLURAL_LABEL = 'Perfiles'
// export const OBJECT_FIELD_PROFILE_TIMEZONE = 'Zona Horaria'
// export const OBJECT_FIELD_PROFILE_HOME_DASHBOARD = 'Panel Principal'
// export const OBJECT_FIELD_PROFILE_MOBILE_DASHBOARD = 'Panel (Aplicación de Teléfono)'
// export const OBJECT_FIELD_PROFILE_CURRENCY = 'Tipo de Moneda'
// export const OBJECT_FIELD_PROFILE_MULTICURRENCY = 'MultiMoneda Activado'

// export const OBJECT_FIELD_PROFILE_LANGUAGE = 'Idioma'
// export const OBJECT_FIELD_PROFILE_EMAIL = 'Correo'

// // support
// // -- support ticket
// // chart
// export const OBJECT_SUPPORT_TICKET_LABEL = 'Caso de Soporte'
// export const OBJECT_SUPPORT_TICKET_PLURAL_LABEL = 'Casos de Soportes'
// export const OBJECT_FIELD_SUPPORT_TICKET_NAME = 'Number'
// export const OBJECT_FIELD_SUPPORT_TICKET_SUBJECT = 'Asunto'
// export const OBJECT_FIELD_SUPPORT_TICKET_STATUS = 'Estado'
  
export const NOTE_INFO_LIMIT_OF_TRANSACTION_RECEIPTS = 'NOTE_INFO_LIMIT_OF_TRANSACTION_RECEIPTS';
export const MESSAGE_RECORD_NOT_EXIST = 'MESSAGE_RECORD_NOT_EXIST';

export const DASHBOARD_SECTION_COMPONENT_TITLE = 'DASHBOARD_SECTION_COMPONENT_TITLE';
export const DASHBOARD_SECTION_COMPONENT_TYPE = 'DASHBOARD_SECTION_COMPONENT_TYPE';
export const DASHBOARD_SECTION_COMPONENT_COMPONENT = 'DASHBOARD_SECTION_COMPONENT_COMPONENT';

export const NAV_SECTION_DATA_LABEL = 'NAV_SECTION_DATA_LABEL';
export const NAV_SECTION_DATA_SYSTEM_LABEL = 'NAV_SECTION_DATA_SYSTEM_LABEL';
export const NAV_SECTION_THIRD_PARTY_LABEL = 'NAV_SECTION_THIRD_PARTY_LABEL';
export const NAV_SECTION_AUTOMATIONS_LABEL = 'NAV_SECTION_AUTOMATIONS_LABEL';
export const NAV_SECTION_TRANSACTIONS_LABEL = 'NAV_SECTION_TRANSACTIONS_LABEL';
export const NAV_SECTION_TRANSACTIONS_TEMPLATES_LABEL = 'NAV_SECTION_TRANSACTIONS_TEMPLATES_LABEL';
export const NAV_SECTION_ACCOUNTS_LABEL = 'NAV_SECTION_ACCOUNTS_LABEL';
export const NAV_SECTION_PAYMENT_METHODS_LABEL = 'NAV_SECTION_PAYMENT_METHODS_LABEL';
export const NAV_SECTION_THIRD_PARTIES_LABEL = 'NAV_SECTION_THIRD_PARTIES_LABEL';

export const NAV_MENU_TRANSACTION_INCOME_LABEL = 'NAV_MENU_TRANSACTION_INCOME_LABEL';
export const NAV_MENU_TRANSACTION_EXPENSE_LABEL = 'NAV_MENU_TRANSACTION_EXPENSE_LABEL';
export const NAV_MENU_TRANSACTION_LIABILITY_LABEL = 'NAV_MENU_TRANSACTION_LIABILITY_LABEL';
export const NAV_MENU_TRANSACTION_LIABILITY_PAYMENT_LABEL = 'NAV_MENU_TRANSACTION_LIABILITY_PAYMENT_LABEL';
export const NAV_MENU_TRANSACTION_ASSET_LABEL = 'NAV_MENU_TRANSACTION_ASSET_LABEL';
export const NAV_MENU_TRANSACTION_EXPENSE_BUDGET_LABEL = 'NAV_MENU_TRANSACTION_EXPENSE_BUDGET_LABEL';

export const NAV_MENU_INCOME_LABEL = 'NAV_MENU_INCOME_LABEL';
export const NAV_MENU_EXPENSE_LABEL = 'NAV_MENU_EXPENSE_LABEL';
export const NAV_MENU_ASSET_LIABILITY_LABEL = 'NAV_MENU_ASSET_LIABILITY_LABEL';
 

export const NAV_MENU_TRANSACTION_TEMPLATE_INCOME_LABEL = 'NAV_MENU_TRANSACTION_TEMPLATE_INCOME_LABEL';
export const NAV_MENU_TRANSACTION_TEMPLATE_EXPENSE_LABEL = 'NAV_MENU_TRANSACTION_TEMPLATE_EXPENSE_LABEL';
export const NAV_MENU_TRANSACTION_TEMPLATE_LIABILITY_LABEL = 'NAV_MENU_TRANSACTION_TEMPLATE_LIABILITY_LABEL';
export const NAV_MENU_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_LABEL = 'NAV_MENU_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_LABEL';
export const NAV_MENU_TRANSACTION_TEMPLATE_ASSET_LABEL = 'NAV_MENU_TRANSACTION_TEMPLATE_ASSET_LABEL';

export const NAV_MENU_ACCOUNT_INCOME_LABEL = 'NAV_MENU_ACCOUNT_INCOME_LABEL';
export const NAV_MENU_ACCOUNT_EXPENSE_LABEL = 'NAV_MENU_ACCOUNT_EXPENSE_LABEL';
export const NAV_MENU_ACCOUNT_LIABILITY_LABEL = 'NAV_MENU_ACCOUNT_LIABILITY_LABEL';
export const NAV_MENU_ACCOUNT_ASSET_LABEL = 'NAV_MENU_ACCOUNT_ASSET_LABEL';

export const NAV_MENU_DATA_REPORT_LABEL = 'NAV_MENU_DATA_REPORT_LABEL';
export const NAV_MENU_DATA_REPORT_STANDARD_LABEL = 'NAV_MENU_DATA_REPORT_STANDARD_LABEL';
export const NAV_MENU_DATA_REPORT_TYPE_LABEL = 'NAV_MENU_DATA_REPORT_TYPE_LABEL';
export const NAV_MENU_DATA_DASHBOARD_LABEL = 'NAV_MENU_DATA_DASHBOARD_LABEL';
export const NAV_MENU_DATA_DASHBOARD_STANDARD_LABEL = 'NAV_MENU_DATA_DASHBOARD_STANDARD_LABEL';
export const NAV_MENU_DATA_CHART_LABEL = 'NAV_MENU_DATA_CHART_LABEL';
export const NAV_MENU_DATA_CHART_STANDARD_LABEL = 'NAV_MENU_DATA_CHART_STANDARD_LABEL';

export const NAV_MENU_DATA_SUPPORT_TICKET_LABEL = 'NAV_MENU_DATA_SUPPORT_TICKET_LABEL';

export const NAV_MENU_USER_AUTOMATED_LABEL = 'NAV_MENU_USER_AUTOMATED_LABEL';
export const NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL = 'NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL';
export const NAV_MENU_AUTOMATION_TRANSACTION_SMS_LABEL = 'NAV_MENU_AUTOMATION_TRANSACTION_SMS_LABEL';
export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_EXPENSE_LABEL = 'NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_EXPENSE_LABEL';
export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_INCOME_LABEL = 'NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_INCOME_LABEL';
export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_ASSET_LABEL = 'NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_ASSET_LABEL';
export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_LIABILITY_PAYMENT_LABEL = 'NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_LIABILITY_PAYMENT_LABEL';

export const NAV_MENU_PAYMENT_METHOD_BANK_LABEL = 'NAV_MENU_PAYMENT_METHOD_BANK_LABEL';
export const NAV_MENU_PAYMENT_METHOD_CARD_LABEL = 'NAV_MENU_PAYMENT_METHOD_CARD_LABEL';
export const NAV_MENU_PAYMENT_METHOD_CASH_LABEL = 'NAV_MENU_PAYMENT_METHOD_CASH_LABEL';

export const NAV_MENU_THIRD_PARTY_VENDOR_LABEL = 'NAV_MENU_THIRD_PARTY_VENDOR_LABEL';
export const NAV_MENU_THIRD_PARTY_BUYER_LABEL = 'NAV_MENU_THIRD_PARTY_BUYER_LABEL';

export const MODAL_MOVE_ACCOUNT_HEADER = 'MODAL_MOVE_ACCOUNT_HEADER';
export const MODAL_MOVE_ACCOUNT_MESSAGE = 'MODAL_MOVE_ACCOUNT_MESSAGE';

export const MODAL_PAYABLE_HEADER = 'MODAL_PAYABLE_HEADER';
export const MODAL_PAYABLE_BODY_MESSAGE = 'MODAL_PAYABLE_BODY_MESSAGE';

export const MODAL_RECEIVABLE_HEADER = 'MODAL_RECEIVABLE_HEADER';
export const MODAL_RECEIVABLE_BODY_MESSAGE = 'MODAL_RECEIVABLE_BODY_MESSAGE';

export const MODAL_PAYMENT_HEADER = 'MODAL_PAYMENT_HEADER';
export const MODAL_PAYMENT_BODY_MESSAGE = 'MODAL_PAYMENT_BODY_MESSAGE';

export const MODAL_DELETE_HEADER = 'MODAL_DELETE_HEADER';
export const MODAL_DELETE_BODY_MESSAGE = 'MODAL_DELETE_BODY_MESSAGE';

export const MODAL_ERROR_HEADER = 'MODAL_ERROR_HEADER';
export const MODAL_ERROR_BODY_MESSAGE = 'MODAL_ERROR_BODY_MESSAGE';

export const INPUT_PLACEHOLDER_SEARCH = 'INPUT_PLACEHOLDER_SEARCH';
export const INPUT_PLACEHOLDER_SEARCH_WITH_PLACE = 'INPUT_PLACEHOLDER_SEARCH_WITH_PLACE';
export const LIST_EMPTY_LABEL = 'LIST_EMPTY_LABEL';
export const ACCOUNT_TREE_PERIOD_EMPTY_LABEL = 'ACCOUNT_TREE_PERIOD_EMPTY_LABEL';

export const COMPONENT_NOT_AVAILABLE_LABEL = 'COMPONENT_NOT_AVAILABLE_LABEL';

export const GENERAL_COMPONENT_MESSAGE__SELECT_LOADING = 'GENERAL_COMPONENT_MESSAGE__SELECT_LOADING';

// export const NOTE_INFO_LIMIT_OF_TRANSACTION_RECEIPTS = 'Solo se pueden subir 3 recibos'
// export const MESSAGE_RECORD_NOT_EXIST = 'El [RECORD_TYPE] que usted esta tratando de acceder no existe'


// export const DASHBOARD_SECTION_COMPONENT_TITLE = 'Seleccionar Componente'
// export const DASHBOARD_SECTION_COMPONENT_TYPE = 'Tipo'
// export const DASHBOARD_SECTION_COMPONENT_COMPONENT = 'Componente'
 
// export const NAV_SECTION_DATA_LABEL = 'Informes y Gráficos'
// export const NAV_SECTION_DATA_SYSTEM_LABEL = 'Informes y Gráficos (Sistema)'
// export const NAV_SECTION_THIRD_PARTY_LABEL = 'Terceros'
// export const NAV_SECTION_AUTOMATIONS_LABEL = 'Automatizaciones'
// export const NAV_SECTION_TRANSACTIONS_LABEL = 'Transacciones'
// export const NAV_SECTION_TRANSACTIONS_TEMPLATES_LABEL = 'Plantillas '
// export const NAV_SECTION_ACCOUNTS_LABEL = 'Categorias'
// export const NAV_SECTION_PAYMENT_METHODS_LABEL = 'Métodos de Pago'
// export const NAV_SECTION_THIRD_PARTIES_LABEL = 'Terceros'

// export const NAV_MENU_TRANSACTION_INCOME_LABEL = 'Transacciones de Ingresos'
// export const NAV_MENU_TRANSACTION_EXPENSE_LABEL = 'Transacciones de Gastos'
// export const NAV_MENU_TRANSACTION_LIABILITY_LABEL = 'Movimientos de Deudas'
// export const NAV_MENU_TRANSACTION_LIABILITY_PAYMENT_LABEL = 'Pago de Deudas'
// export const NAV_MENU_TRANSACTION_ASSET_LABEL = 'Registros de Activos / Deudas'
// export const NAV_MENU_TRANSACTION_EXPENSE_BUDGET_LABEL = 'Presupuesto de Gastos'

// export const NAV_MENU_TRANSACTION_TEMPLATE_INCOME_LABEL = 'Planillas de T. Ingresos'
// export const NAV_MENU_TRANSACTION_TEMPLATE_EXPENSE_LABEL = 'Planillas de T. Gastos'
// export const NAV_MENU_TRANSACTION_TEMPLATE_LIABILITY_LABEL = 'Planillas de Deudas'
// export const NAV_MENU_TRANSACTION_TEMPLATE_LIABILITY_PAYMENT_LABEL = 'Planillas de Pago de Deudas'
// export const NAV_MENU_TRANSACTION_TEMPLATE_ASSET_LABEL = 'Planillas de Activos'
 
// export const NAV_MENU_ACCOUNT_INCOME_LABEL = 'Categorias de Ingresos'
// export const NAV_MENU_ACCOUNT_EXPENSE_LABEL = 'Categorias de Gastos'
// export const NAV_MENU_ACCOUNT_LIABILITY_LABEL = 'Categorias de Deudas'
// export const NAV_MENU_ACCOUNT_ASSET_LABEL = 'Categorias de Activos'

// export const NAV_MENU_DATA_REPORT_LABEL = 'Reportes'
// export const NAV_MENU_DATA_REPORT_STANDARD_LABEL = 'Reportes de Sistema'
// export const NAV_MENU_DATA_REPORT_TYPE_LABEL = 'Tipos de Reporte'
// export const NAV_MENU_DATA_DASHBOARD_LABEL = 'Paneles'
// export const NAV_MENU_DATA_DASHBOARD_STANDARD_LABEL = 'Paneles de Sistema'
// export const NAV_MENU_DATA_CHART_LABEL = 'Gráficos'
// export const NAV_MENU_DATA_CHART_STANDARD_LABEL = 'Gráficos de Sistema'

// export const NAV_MENU_DATA_SUPPORT_TICKET_LABEL = 'Casos'

// export const NAV_MENU_USER_AUTOMATED_LABEL = 'Correos'
// export const NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL = 'Correos'
// export const NAV_MENU_AUTOMATION_TRANSACTION_SMS_LABEL = 'SMSs'
// export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_EXPENSE_LABEL = 'Generador de Gastos'
// export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_INCOME_LABEL = 'Generador de Ingresos'
// export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_ASSET_LABEL = 'Generador de Activos'
// export const NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_LIABILITY_PAYMENT_LABEL = 'Generador de Pagos de Deudas'
 
// export const NAV_MENU_PAYMENT_METHOD_BANK_LABEL = 'Bancos'
// export const NAV_MENU_PAYMENT_METHOD_CARD_LABEL = 'Tarjetas de Credito'
// export const NAV_MENU_PAYMENT_METHOD_CASH_LABEL = 'Bolsillos'

// export const NAV_MENU_THIRD_PARTY_VENDOR_LABEL = 'Vendedores'
// export const NAV_MENU_THIRD_PARTY_BUYER_LABEL = 'Compradores'


// export const  MODAL_MOVE_ACCOUNT_HEADER = 'Mover'
// export const  MODAL_MOVE_ACCOUNT_MESSAGE = 'Esta cuenta va a ser movida para el padre seleccionado'

// export const  MODAL_PAYABLE_HEADER = 'Deuda'
// export const  MODAL_PAYABLE_BODY_MESSAGE = 'Este Ingreso va a ser convertido a una Deuda. Esta seguro?'

// export const  MODAL_RECEIVABLE_HEADER = 'Activo'
// export const  MODAL_RECEIVABLE_BODY_MESSAGE = 'Este gasto va a ser convertido a un Activo. Esta seguro?'

// export const  MODAL_PAYMENT_HEADER = 'Pago de Deuda'
// export const  MODAL_PAYMENT_BODY_MESSAGE = 'Este gasto va a ser convertido a un Pago de Deuda. Esta seguro?'

// export const  MODAL_DELETE_HEADER = 'Eliminar'
// export const  MODAL_DELETE_BODY_MESSAGE = '[RECORD_NAME] va a ser eliminado. Esta seguro?'

// export const  MODAL_ERROR_HEADER = 'Error'
// export const  MODAL_ERROR_BODY_MESSAGE = 'ERROR'

// export const  INPUT_PLACEHOLDER_SEARCH = 'Buscar...'
// export const INPUT_PLACEHOLDER_SEARCH_WITH_PLACE = 'Buscar[PLACE] ...'
// export const  LIST_EMPTY_LABEL = 'Lista Vacia'
// export const  ACCOUNT_TREE_PERIOD_EMPTY_LABEL = 'Este periodo no tiene datos'

// export const  COMPONENT_NOT_AVAILABLE_LABEL = 'El registro que esta tratando de acceder no esta disponible'

// export const  GENERAL_COMPONENT_MESSAGE__SELECT_LOADING = 'Cargando...'
 

// // payment method - bank
// export const OBJECT_PAYMENT_METHOD_BANK_LABEL = 'Banco'
// export const OBJECT_PAYMENT_METHOD_BANK_PLURAL_LABEL = 'Bancos'
// export const OBJECT_FIELD_PAYMENT_METHOD_BANK_CODE1 = '4 Ultimos Números (Cuenta)'
// export const OBJECT_FIELD_PAYMENT_METHOD_BANK_CODE2 = '4 Ultimos Números (Tarjeta)'

// // payment method - card
// export const OBJECT_PAYMENT_METHOD_CARD_LABEL = 'Tarjeta de Credito'
// export const OBJECT_PAYMENT_METHOD_CARD_PLURAL_LABEL = 'Tarjetas de Credito'
// export const OBJECT_FIELD_PAYMENT_METHOD_CARD_CODE1 = '4 Ultimos Números (Tarjeta)'

// error message by code
export const ERROR_CODE_00000 = 'Error desconocido ha ocurrido'

export const ERROR_CODE_00001 = 'Los valores no son iguales'
export const ERROR_CODE_00002 = 'Los valores no son iguales'


