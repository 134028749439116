import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './utilities/redux/store.js';
import Container from './user/containers';
import { useTranslation } from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './App.css';

const useWindowSize = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isMobile;
};

function App() {
    const isMobile = useWindowSize();
    const { t, i18n } = useTranslation();
 
    useEffect(() => {
        document.body.classList.add(isMobile ? 'application-mobile' : 'application-desktop');
    }, [isMobile]);

    const isProduction = process.env.NODE_ENV === 'production';

    return (
        <HelmetProvider>
            <div className={`app`}>
                <Provider store={store}>
                    <Helmet>
                        {isProduction && (
                            <>
                                <script async src="https://www.googletagmanager.com/gtag/js?id=G-L4FBNZ2V0G"></script>
                                <script>
                                    {`
                                        window.dataLayer = window.dataLayer || [];
                                        function gtag(){dataLayer.push(arguments);}
                                        gtag('js', new Date());
                                        gtag('config', 'G-L4FBNZ2V0G');
                                    `}
                                </script>
                            </>
                        )}
                        <html lang={i18n.language} />
                        <title>{t('meta.title')}</title>
                        <meta name="description" content={t('meta.description')} />
                        <meta name="keywords" content={t('meta.keywords')} />
                    </Helmet>
                    <BrowserRouter>
                        <Container />
                    </BrowserRouter>
                </Provider>
            </div>
        </HelmetProvider>
    );
}

export default App;