import { createSlice, current } from '@reduxjs/toolkit'

const chatbotSlice = createSlice({
    name: 'chatbot',
    initialState: { loading: false, messsages: [], error: undefined },
    reducers: {
         
        add(state, action) {
            state.loading = true
        },
        
        addResponse(state, action) {
            
            state.loading = false
        },
        
        getAll(state, action) {
            state.loading = true
        },
        
        getAllResponse(state, action) {
            state.loading = false
            state.messsages = action.payload
        },
         
        
        error(state, action) {
            state.loading = false
            state.error = action.payload
            
            console.log(action.payload)
        }
    }
})

export const { 
    
    add, addResponse, 
    getAll, getAllResponse,
    error
} = chatbotSlice.actions;

export default chatbotSlice.reducer