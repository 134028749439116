import { Route } from "react-router-dom";
  
import LandingPage from '../../user/landing-page'
import MobileAuthenticationPage from '../../user/landing-page/mobile-authentication-page'
 
const routes =  
    <Route path="/">
      
        <Route index element={<LandingPage />} />
        
        <Route path="m/:token/:action" element={<MobileAuthenticationPage />} /> 
  
    </Route> 

export default routes
