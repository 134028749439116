import { Route } from "react-router-dom";
   
import HomePage from '../../user/home-page' 
import MobileAuthenticationPage from '../../user/landing-page/mobile-authentication-page'

const routes =  
    <Route path="/" >
       
        <Route index element={<HomePage />} />

        <Route path="m/:token/:action" element={<MobileAuthenticationPage />} /> 

        <Route path="home" element={<HomePage />} />

    </Route> 

export default routes
