import { Route } from "react-router-dom";
 
import PaymentContainer from "../../user/payments/payment-container";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/payment">
 
            <Route index element={<PaymentContainer />} />
 

        </Route> 
 
export default routes
