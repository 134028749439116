import React from 'react';
import Chat from '../chat';
import * as service from '../../utilities/services/chatbot-finance-service';

const FinanceAssistanceChat = ({ show, close, user, dispatch }) => {
   
    return (
        <Chat
            show={show}
            onHide={close}
            service={service}
            user={user}
            dispatch={dispatch}
            welcomeMessage="financeAssistantWelcome"
            inputPlaceholder="financeAssistantPlaceholder"
            errorTranslationKey="financeAssistantError"
            title="financeAssistantTitle"
            useModal={true}
            modalClassName="finance-assistance-modal"
        />
    );
};

export default FinanceAssistanceChat;