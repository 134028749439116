import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { useTranslation } from 'react-i18next';

import ActiveSubscription from "./active-subscription";
import './payment-container.css';

import PayUComponent from "./payu";

import UserContext from '../user-context';
import * as s from '../../utilities/services/subscription-service';
 
const PaymentContainer = ({}) => {
    
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const user = useContext(UserContext);
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);

    document.title = `${t("payment.title")}`;

    const dispatch = useDispatch();

    const messages = {
        es: {
            title: "Versión Premium",
            message: "La versión premium estará disponible próximamente. ¡Gracias por tu interés!"
        },
        en: {
            title: "Premium Version",
            message: "The premium version will be available soon. Thank you for your interest!"
        }
    };

    const currentLang = i18n.language.startsWith('es') ? 'es' : 'en';

    useEffect(() => { 
        /*const load = async () => {
            try {
                const response = await s.info({ user }, dispatch);
                if(response.success)
                    setInfo(response.data);
            } finally {
                setLoading(false);
            }
        };

        load();*/
    }, []);
     
    /*if(loading) return (
        <div className="container payment-container">
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">{t('common.loading')}</span>
                </div>
            </div>
        </div>
    );
  
    if(!info) return <div />;
   
    if(info.subscription) {
        return (
            <ActiveSubscription subscription={info.subscription} />
        );
    }
        
    return (
        <div className="container payment-container">
            <div className="row justify-content-center"> 
                {
                    info && <PayUComponent user={user} dispatch={dispatch} info={info} t={t}/>
                }
            </div>
        </div>
    );*/

    return (
        <div className="container payment-container">
            <div className="row justify-content-center">
                <div className="col-md-8 text-center py-5">
                    <div className="mb-4">
                        <i className="fas fa-crown text-warning" style={{ fontSize: '4rem' }}></i>
                    </div>
                    <h2 className="fw-bold text-primary ">
                        {messages[currentLang].title}
                    </h2>
                    <p className="lead text-muted " style={{ fontSize: '1.25rem' }}>
                        {messages[currentLang].message}
                    </p>
                    <div className="d-flex justify-content-center">
                        <i className="fas fa-star text-warning mx-2" style={{ fontSize: '1.5rem' }}></i>
                        <i className="fas fa-star text-warning mx-2" style={{ fontSize: '1.5rem' }}></i>
                        <i className="fas fa-star text-warning mx-2" style={{ fontSize: '1.5rem' }}></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentContainer;