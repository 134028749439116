import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import i18n from 'i18next'

const updateStoredUser = (newUserData) => { 
    
    const currentCookie = Cookies.get('ut') 
    if (!currentCookie) return
     
    const currentUser = JSON.parse(currentCookie)
    const updatedUser = { ...currentUser, ...newUserData }

    const currentOptions = Cookies.get('ut', { withAttributes: true })
    const { expires } = currentOptions

    // Mantener la fecha de expiración original
    Cookies.set('ut', JSON.stringify(updatedUser), { 
        expires: new Date(expires),
        secure: process.env.REACT_APP_NODE_ENV !== 'dev',
        sameSite: process.env.REACT_APP_NODE_ENV === 'dev' ? 'Lax' : 'Strict'
    })
}

const userProfileSlice = createSlice({
    name: 'user-profile',
    initialState: { loading: false,  item: undefined, error: undefined },
    reducers: {
       
      get(state, action) {
        state.loading = true
      },

      getResponse(state, action) {

        state.loading = false
        state.item = action.payload 
      },

      update(state, action) {
        state.loading = true
      },

      updateResponse(state, action) {

        state.loading = false
        state.item = action.payload
 
        // from here you should update the user
        updateStoredUser({
            currency_id: action.payload.currency_id,
            currency_code: action.payload.currency_code,
            language_code: action.payload.language_code,
            is_multicurrency: action.payload.is_multicurrency,
        })
 
        // Change the language using i18n
        if (action.payload.language_code) {
            i18n.changeLanguage(action.payload.language_code)
        }

      },
 
      error(state, action) {
          state.loading = false
          state.error = action.payload
  
          console.log(action.payload)
      }
    }
})

export const { 
    get, getResponse,
    update, updateResponse,
    error
} = userProfileSlice.actions;

export default userProfileSlice.reducer