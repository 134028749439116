import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import * as s from '../../utilities/services/account-asset-service'
import * as saccount from '../../utilities/services/account-service'

import TreeItemDetailPage from '../../common/tree-layouts/pages/tree-item-detail-page'
import configs from './configs'
import SetBalanceContainerModal from "../../common/modals/set-balance-container-modal";

const AccountAssetDetailPage = () => {
    const [showSetBalanceModal, setShowSetBalanceModal] = useState(false)
   
    configs.c_actions = [
        {
            label: 'BUTTON_SET_BALANCE_LABEL',
            handler: () => setShowSetBalanceModal(true),
            visibilityFn: (e, user) => {
                return e.is_available_leaf
            }
        }
    ]
  
    return (
        <TreeItemDetailPage service={s} configs={configs}>
            {showSetBalanceModal &&
                <SetBalanceContainerModal 
                    close={() => setShowSetBalanceModal(false)} 
                    configs={configs} 
                    showOnlyInitial={true}
                    service={s} 
                />
            }
        </TreeItemDetailPage>
    )
}

export default AccountAssetDetailPage