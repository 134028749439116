import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiCheckCircle, mdiAlertCircle, mdiCommentText } from '@mdi/js';
import './unsubscribe.css';
import ActiveSubscription from './active-subscription';
import UserContext from '../user-context';

import * as service from '../../utilities/services/subscription-service';

const UnsubscribeComponent = ({   }) => {
    const { t } = useTranslation();
    const [unsubscribeStatus, setUnsubscribeStatus] = useState(null);
    const [unsubscribeInProgress, setUnsubscribeInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [reason, setReason] = useState('');
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rollbackSuccess, setRollbackSuccess] = useState(false);

    const user = useContext(UserContext);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const handleUnsubscribe = async () => {
        setUnsubscribeInProgress(true);
        setErrorMessage(null);

        try {
            const response = await service.cancel({ payload: { reason }, user }, dispatch);
            if (response.success) {
                navigate('/subscriptions/payment');
            } else {
                throw new Error(response.message || t('unsubscribe.error'));
            }
        } catch (error) {
            console.error('Unsubscribe error:', error);
            setErrorMessage(t('unsubscribe.error_message', { message: error.message }));
        } finally {
            setUnsubscribeInProgress(false);
        }
    };
  
    useEffect(() => {
        const load = async () => {
            try {
                const response = await service.info({ user }, dispatch);
                
                if(response.success) {
                    setInfo(response.data);
                    if (!response.data?.subscription?.auto_renewal) {
                        navigate('/subscriptions/payment');
                    }
                }
            } finally {
                setLoading(false);
            }
        };

        load();
    }, []); 
 
   
    if(!info?.subscription) 
        return <div className="payu-container"><div className="payu-spinner"></div></div>;

    return (
        <div>
            <div className="payu-container">
                <h2 className="text-center payu-title">{t('unsubscribe.title')}</h2>
                <p className="text-center">{t('unsubscribe.description')}</p>

                <div className="unsubscribe-warning text-center">
                    <Icon path={mdiAlertCircle} size={1.5} className="warning-icon" color="#d9534f" />
                    <p>{t('unsubscribe.warning')}</p>
                </div>

                {/* Campo para ingresar la razón de la cancelación */}
                <div className="unsubscribe-reason">
                    <label htmlFor="unsubscribeReason" className="form-label">
                        <Icon path={mdiCommentText} size={1} className="me-2" />
                        {t('unsubscribe.reason_label')}
                    </label>
                    <textarea 
                        id="unsubscribeReason" 
                        className="form-control" 
                        rows="3" 
                        placeholder={t('unsubscribe.reason_placeholder')}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </div>

                {errorMessage && (
                    <div className="alert alert-danger text-center">{errorMessage}</div>
                )}

                <button 
                    className="payu-submit-button w-100 unsubscribe-button" 
                    onClick={handleUnsubscribe} 
                    disabled={unsubscribeInProgress}
                >
                    {unsubscribeInProgress ? t('unsubscribe.processing') : t('unsubscribe.button')}
                </button>
            </div>
        </div>
    );
};

export default UnsubscribeComponent;