import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';

import * as uservice from '../../utilities/services/user-service'

const MobileAuthenticationPage = () => {
 
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { token, action } = useParams()
 
    const activateByToken = async () => {
        
        const response = await  uservice.activateByToken({user:{ access_token: token}}, dispatch)
        if (response.success) {
            if(action === 'login') {
                navigate('/');
            } else if(action === 'subscribe') {
                navigate('/subscriptions/payment');
            } else {
                navigate('/m/login');
            }
        } else {
            setError(response.message);
        }
    }

    useEffect(()=> {
        activateByToken()
    }, [])

    return (
        <div></div>
    )
}

export default MobileAuthenticationPage;