import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'

import AnonymousContainer from "../anonymous";
import AuthenticatedContainer from "../authenticated"; 
 
const Container = () => {
 
    const user = useSelector((state) => state.user.user)
  
    // Si la ruta comienza con "/documents", mostrar siempre el contenedor anónimo
    if (location.pathname.startsWith("/documents")) {
        return <AnonymousContainer />;
    }
    
    return (
        <div  >  
            {   /** AnonymousContainer if not user login */ 
                user? <AuthenticatedContainer user={user} />: <AnonymousContainer />
            } 
        </div>
        
    )
}

export default Container