import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiFacebook, mdiYoutube, mdiInstagram } from '@mdi/js';

import LandingHeader from './landing-header';
import LandingMain from './landing-main';
import PricingPlans from './landing-plans';
import ContactUsSection from './landing-contactus';
import * as docservice from '../../utilities/services/document-service';
import * as phraseservice from '../../utilities/services/phrase-service';
import { DOCUMENT_TERMS_CONDITIONS } from "../../common/configurations/constants";
import LandingFeatures from './landing-features';
import LandingSignin from './landing-signin';
import LandingBooks from './landing-books';
import LandingGlossary from './landing-glossary';
 
import './landing-page.css';

function LandingPage({ initialPage }) {
    
    const navigate = useNavigate();
    
    const [phrase, setPhrase] = useState('');
    const [loadingPhrase, setLoadingPhrase] = useState(true);
    const [showPricing, setShowPricing] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);
    const [showBooks, setShowBooks] = useState(false);
    const [showGlossary, setShowGlossary] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showTerms, setShowTerms] = useState(false);

    const { t, i18n } = useTranslation();
     
    useEffect(() => {
        // Obtener el idioma del navegador
        const browserLanguage = navigator.language || navigator.languages[0];
        const shortLang = browserLanguage.split('-')[0]; // Para obtener solo el código principal, ej. 'en'
    
        // Cambiar el idioma en i18n si es diferente al actual
        if (i18n.language !== shortLang) {
            i18n.changeLanguage(shortLang);
        }
  
    }, [i18n.language]);
    
    useEffect(() => {

        if (initialPage === 'terms') {
            setShowTerms(true);
        } else if (initialPage === 'privacy') {
            setShowPrivacy(true);
        }
    }, [initialPage]);

    useEffect(() => {
        const load = async () => {
            try { 
                const response3 = await phraseservice.get({ language: i18n.language });
                if (response3.success) setPhrase(response3.data);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoadingPhrase(false);
            }
        }
        load();
    }, [i18n.language]);
     
    const handlePricingClick = (e) => {
        e.preventDefault();
        setShowPricing(true);
        setShowContact(false);
    };

    const handleContactClick = (e) => {
        e.preventDefault();
        setShowContact(true);
        setShowPricing(false);
    };

    const handleLogoClick = () => {
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
        setShowFeatures(false);
        setShowBooks(false);
        setShowGlossary(false);
        setShowPrivacy(false);
        setShowTerms(false);
    };

    const handleSignInClick = () => {
        setShowSignIn(true);
        setShowPricing(false);
        setShowContact(false);
    };

    const handleFeaturesClick = (e) => {
        e.preventDefault();
        setShowFeatures(true);
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
    };

    const handleBackToHome = () => {
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
    };

    const handleBooksClick = (e) => {
        e.preventDefault();
        setShowBooks(true);
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
        setShowFeatures(false);
        setShowGlossary(false);
        setShowPrivacy(false);
        setShowTerms(false);
    };

    const handleGlossaryClick = (e) => {
        e.preventDefault();
        setShowGlossary(true);
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
        setShowFeatures(false);
        setShowBooks(false);
        setShowPrivacy(false);
        setShowTerms(false);
    };

    const handlePrivacyClick = (e) => {
        navigate('/privacy',{target: '_blank'});
    };

    const handleTermsClick = (e) => {
        navigate('/terms',{target: '_blank'});
    };

    return (
        <div className="App">
            <div className="watermark"></div>
            
            <LandingHeader 
                t={t} 
                i18n={i18n} 
                handlePricingClick={handlePricingClick}
                handleContactClick={handleContactClick}
                handleLogoClick={handleLogoClick}
                handleSignInClick={handleSignInClick}
                handleFeaturesClick={handleFeaturesClick}
            />

            <Container fluid>
                {showPricing ? (
                    <PricingPlans t={t} i18n={i18n} handleSignInClick={handleSignInClick}/>
                ) : showContact ? (
                    <ContactUsSection t={t} />
                ) : showSignIn ? (
                    <LandingSignin t={t} i18n={i18n} handleBackToHome={handleBackToHome} />
                ) : showFeatures ? (
                    <LandingFeatures t={t} />
                ) : showBooks ? (
                    <LandingBooks t={t} />
                ) : showGlossary ? (
                    <LandingGlossary t={t} />
                ) : showPrivacy ? (
                    <LandingPrivacy t={t} i18n={i18n} />
                ) : showTerms ? (
                    <LandingTerms t={t} i18n={i18n} />
                ) : (
                    <>
                        <LandingMain 
                            t={t} 
                            i18n={i18n} 
                            loadingPhrase={loadingPhrase} 
                            phrase={phrase} 
                            onSignInClick={handleSignInClick}
                        />
                        
                    </>
                    
                )}
            </Container>
 
            <footer className="footer">
                <div className="footer-links-section">
                    <div className="footer-links-column">
                        <a href="#" onClick={handleBooksClick}>{t('landing-book.recommendedTitle')}</a>
                        <a href="#" onClick={handleGlossaryClick}>{t('landing-glossary.title')}</a>
                    </div>
                    <div className="footer-links-column">
                        <a href="#" onClick={(e) => { 
                            e.preventDefault(); 
                            window.open('/documents/terms', '_blank');
                        }}>{t('landing-page.terms')}</a>

                        <a href="#" onClick={(e) => { 
                            e.preventDefault(); 
                            window.open('/documents/privacy', '_blank');
                        }}>{t('landing-page.privacy')}</a>

                        <a href="#" onClick={(e) => { 
                            e.preventDefault(); 
                            window.open('/documents/help/web', '_blank');
                        }}>{t('landing-page.help')}</a>
                    </div>
                </div>
                <div className="footer-content">
                    <div className="footer-content-inner">
                        <span>{t('footerRights')}</span>
                        <div className="social-media-links">
                            <a href="https://www.facebook.com/profile.php?id=61567121362684" target="_blank" rel="noopener noreferrer">
                                <Icon path={mdiFacebook} size={1} />
                            </a>
                            <a href="https://www.youtube.com/@FinanceGo360" target="_blank" rel="noopener noreferrer">
                                <Icon path={mdiYoutube} size={1} />
                            </a>
                            <a href="https://www.instagram.com/financego360/" target="_blank" rel="noopener noreferrer">
                                <Icon path={mdiInstagram} size={1} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;