import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import './landing-plans.css';

const PricingPlans = ({ t, handleSignInClick }) => {
  const plans = [
    {
      name: t('landing-plan.free.name'),
      price: '0',
      period: '',
      features: [
        t('landing-plan.free.feature-1'),
        t('landing-plan.free.feature-2'),
        t('landing-plan.free.feature-3'),
        t('landing-plan.free.feature-4'),
        t('landing-plan.free.feature-5'),
        t('landing-plan.free.feature-6'),
        t('landing-plan.premium-monthly.feature-4')
      ],
      footerText: t('landing-plan.free.footerText'),
      highlighted: false,
      button: true
    },
    {
      name: t('landing-plan.premium-monthly.name'),
      monthlyPrice: '2.99',
      annualMonthlyPrice: '2.49',
      features: [
        t('landing-plan.premium-monthly.feature-1'),
        t('landing-plan.premium-monthly.feature-2'),
        t('landing-plan.premium-monthly.feature-3'),
        t('landing-plan.premium-monthly.feature-5')
      ],
      footerText: t('landing-plan.premium-monthly.footerText'),
      highlighted: false
    }
  ];

  return (
    <section className="pricing-section py-4">
      <div className="container">
        <div className="text-center mb-4">
          <h2 className="section-title">{t('landing-plan.title')}</h2>
          <p className="text-muted mb-3">{t('landing-plan.description')}</p>
        </div>
        
        <div className="row justify-content-center g-4">
          {plans.map((plan, index) => (
            <div key={index} className="col-xl-5 col-lg-6 col-md-6">
              <div className={`card pricing-card h-100 ${plan.highlighted ? 'pricing-card-highlighted' : ''}`}>
                <div className="card-header text-center bg-white border-0 ">
                  <h3 className="card-title mb-0 fs-3">{plan.name}</h3>
                </div>
                <div className="card-body text-center p-0">
                  {plan.price ? (
                    <div className="pricing-value py-2">
                      <span className="currency">$</span>
                      <span className="price">{plan.price}</span>
                      <span className="period">{plan.period}</span>
                    </div>
                  ) : (
                    <div className="pricing-value py-2">
                      <div>
                        <span className="currency">$</span>
                        <span className="price">{plan.annualMonthlyPrice}</span>
                        <span className="period">/{t('landing-plan.period.month')}</span>
                        <div className="text-muted">{t('landing-plan.annual-billing')}</div>
                      </div>
                      <div className="text-muted mt-2">
                        {t('landing-plan.monthly-price', { price: plan.monthlyPrice })}
                      </div>
                    </div>
                  )}
                  
                  <ul className="list-unstyled feature-list px-2 py-2 row">
                    {plan.features.map((feature, featureIndex) => (
                      <li
                        key={featureIndex}
                        className="mx-3 d-flex align-items-start text-start col-12"
                      >
                        <Icon
                          path={mdiCheckCircle}
                          size={1}
                          className="feature-icon me-3 mt-1"
                        />
                        <span className="feature-text">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="card-footer text-center bg-white border-0">
                  {plan.button ? (
                    <button 
                      className="btn btn-custom-primary landing-plans landing-plans_btn-full-width py-3"   
                      onClick={handleSignInClick}
                    >
                      {plan.footerText}
                    </button>
                  ) : (
                    <p className="text-muted mb-0">{plan.footerText}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-5">
          <p className="lead mb-2">{t('landing-plan.callToAction')}</p>
          <p className="text-muted">{t('landing-plan.upgradeMessage')}</p>
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;