import { Route } from "react-router-dom";
 
import PaymentContainer from "../../user/payments/payment-container";
import UnsubscribeComponent from "../../user/payments/unsubscribe";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/subscriptions">
 
            <Route index element={<PaymentContainer />} />

            <Route path="payment" element={<PaymentContainer />} />
            <Route path="cancel" element={<UnsubscribeComponent />} />
            
        </Route> 
 
export default routes
