import { Route } from "react-router-dom";

import HelpDocument from '../../documents/help-document'
import PrivacyDocument from '../../documents/privacy-document'
import TermsDocument from '../../documents/terms-document'
import DeleteAccountGuide from '../../documents/delete-account-guide'
// maybe change write "setup" instead "config"
const routes =  
        <Route path="documents">
            <Route path="terms" element={<TermsDocument />} /> 
            <Route path="privacy" element={<PrivacyDocument />} /> 
            <Route path="help/:device" element={<HelpDocument />} /> 
            <Route path="delete-account-guide" element={<DeleteAccountGuide />} /> 
        </Route> 
 
export default routes
